import * as React from 'react'
const PieChart = require('react-minimal-pie-chart/lib')
import { config } from '~/config'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withAdminFetcher } from '~/src/utils'
import { isNil, forEach, uniq, get, isEmpty, includes, startsWith } from 'lodash'
import { Alert } from 'react-bootstrap'
import BroadcastMessageAlert from './base/BroadcastMessageAlert'
import {
  IEServiceError
} from '@optum-wvie/dynamic-ui-shared/src/utils'
import { AdminPortalException, CODES, shouldThrow } from './Errors'
import * as actions from '~/src/redux/actions'
const _ = { isNil, forEach, uniq, get, isEmpty, includes, startsWith }
import ScreenPreLoader from '@optum-wvie/dynamic-ui-shared/components/ScreenPreLoader'

const apLicenseInfoEndpoint = config['endpoint']['apLicenseInfo']
const userRoleDismissInactiveNotifyEndpoint =
  config['endpoint']['userRoleDismissInactiveNotify']
const ssoEndpoint = config['endpoint']['singleSignOn']
const getMyBroadcastsEndPoint = config['endpoint']['getMyBroadcasts']
const ISSUED_COLOR = '#0E5D4E'
const AVAILABLE_COLOR = '#428BCA'
import { getAgencyPortalName } from '@optum-wvie/dynamic-ui-shared/src/utils'
//TODO: Split into container & presentation?

interface DashboardProps {
  uuid?: string
  authHeader?: string
  nonDismissedExpiredUserRoleIds: any
  setUserRolesDismissed: any
  accessToken: any
  userAccount: any
  adminFetch: (endpoint: string, request?: {
    method?: any;
    headers?: any;
    body?: any;
    mode?: any;
  }, retries?: number, timeoutMs?: number) => Promise<any>
  activeEntitlements: Array<String>
}

class Dashboard extends React.Component<DashboardProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      callingApi: false,
      sfIsConfigured: undefined,
      sfLicenseIssuedCount: undefined,
      sfLicenseAvailableCount: undefined,
      broadcastMessages: []
    }
  }

  componentDidMount() {
    this.setState({ callingApi: true })
    this.loadBroadcastMessages()
    this.props.adminFetch(
      apLicenseInfoEndpoint,
      {
        method: 'GET'
      },
      0
    )
      .then(response => {
        let sfIsConfigured, sfLicenseIssuedCount, sfLicenseAvailableCount
        if (response && response['TotalLicenses'] && response['UsedLicenses']) {
          sfIsConfigured = true
          sfLicenseIssuedCount = response['UsedLicenses']
          sfLicenseAvailableCount =
            response['TotalLicenses'] - response['UsedLicenses']
        } else {
          sfIsConfigured = false
          sfLicenseIssuedCount = undefined
          sfLicenseAvailableCount = undefined
        }
        this.setState({
          callingApi: false,
          sfIsConfigured,
          sfLicenseIssuedCount,
          sfLicenseAvailableCount
        })
      })
      .catch(error => {
        this.setState({
          callingApi: false,
          sfIsConfigured: false,
          sfLicenseIssuedCount: undefined,
          sfLicenseAvailableCount: undefined
        })
      })
  }

  validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  singleSignOn = (targetPortalName, targetPortalRoute) => {
    const ssoRequest = {
      targetPortalName,
      targetPortalRoute
    }
    //TODO: ScreenPreLoader?
    this.props.adminFetch(ssoEndpoint, {
      method: 'POST',
      body: JSON.stringify(ssoRequest)
    })
    .then(redirectUrl => {
      if (!_.isNil(redirectUrl) && !_.isEmpty(redirectUrl)) {
        if (this.validURL(redirectUrl)) {
          console.log("SSO redirecting to: " + redirectUrl)
          window.location.href = redirectUrl
        } else {
          console.error("SSO returned invalid URL: " + redirectUrl)
        }
      }
    })
    .catch(error => {
      console.error(
        'Dashboard singleSignOn failed at endpoint ' + ssoEndpoint + ' with error:', error
      )
    })
  }

  loadBroadcastMessages = () => {
    this.props.adminFetch(
      getMyBroadcastsEndPoint,
      {
        method: 'GET'
      },
      0
    )
      .then(response => {
        this.setState({
          broadcastMessages: response
        })
      })
      .catch(error => {
        console.error('Failed to fetch broadcast messages with error: ', error)
        this.setState({
          callingApi: false
        })
      })

  }

  render() {
    const { activeEntitlements } = this.props
    const {
      callingApi,
      sfIsConfigured,
      sfLicenseIssuedCount,
      sfLicenseAvailableCount,
      broadcastMessages
    } = this.state
    const sfLicenseChartData = []
    if (sfLicenseIssuedCount && sfLicenseIssuedCount > 0) {
      sfLicenseChartData.push({
        title: 'Issued',
        value: sfLicenseIssuedCount,
        color: ISSUED_COLOR
      })
    }
    if (sfLicenseAvailableCount && sfLicenseAvailableCount > 0) {
      sfLicenseChartData.push({
        title: 'Available',
        value: sfLicenseAvailableCount,
        color: AVAILABLE_COLOR
      })
    }

    return (
      <div className="dashboard">
        <h1>Dashboard</h1>
        {(this.props.nonDismissedExpiredUserRoleIds || []).length > 0 && (
          <Alert variant={'danger'}>
            <div
              className="row"
              style={{
                marginLeft: '10px',
                marginRight: '10px',
                marginBottom: '0px'
              }}
            >
              <div
                className="col-10 offset-1 text-center"
                style={{ marginTop: '7px' }}
              >
                Due to inactivity, one or more of your roles have expired. For
                any questions, please contact [SUPPORT_EMAIL] or [SUPPORT_PHONE]
              </div>
              <button
                type="button"
                className="btn btn-link btn-link-danger pull-right col-1"
                onClick={this.dismissInactiveRoleNotification}
                aria-label="Dismiss Inactivity message"
              >
                Dismiss
              </button>
            </div>
          </Alert>
        )}
        {
          broadcastMessages.map(broadcastMessage => (
            <BroadcastMessageAlert broadcastMessage={broadcastMessage} variant={'info'} key={broadcastMessage.id} />
          ))
        }
        <div className="row">
          {config.tenant.code.toUpperCase() === 'WV' &&
            <div className="sso-link pull-left">
              <a ref={node => node && node.setAttribute('href', 'JavaScript:void(0)')} onClick={() => this.singleSignOn('clp', '')}>
                SSO to Common Landing Page
              </a>
            </div>
          }
          {/* <div className="sso-link pull-left">
            <a ref={node => node && node.setAttribute('href', 'JavaScript:void(0)')} onClick={() => this.singleSignOn('cp', '')}>
              SSO to Client Portal
            </a>
          </div>
          <div className="sso-link pull-left">
            <a ref={node => node && node.setAttribute('href', 'JavaScript:void(0)')} onClick={() => this.singleSignOn(
                getAgencyPortalName(config.tenant.code, config.environment), ''
              )}>
              SSO to Agency Portal
            </a>
          </div> */}
        </div>
        {sfIsConfigured && (
          <div className="row d-block no-ie-block dashboard-pie-chart bg-white">
            <h2>
              {`Salesforce License Usage - ${config.tenant.code.toUpperCase()} ${
                config.environment
              }`}
            </h2>
            <div>
              {!_.isNil(sfLicenseIssuedCount) &&
                !_.isNil(sfLicenseAvailableCount) && (
                  <PieChart
                    data={sfLicenseChartData}
                    label={true}
                    labelStyle={{
                      fontSize: '5px',
                      fontFamily: 'Roboto-Light',
                      fill: '#FFFFFF'
                    }}
                    labelPosition={sfLicenseChartData.length > 1 ? 50 : 0}
                    style={{ height: '300px' }}
                    animate={true}
                  />
                )}
              <Table bordered hover size="sm" summary="Column one has the count of issued Salesforce License, other columns show the count of available Salesforce License.">
                <caption>
                  License Usage<br/>
                  <span></span>
                </caption>
                <thead>
                  <tr>
                    <th colSpan={3}>Legend</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: ISSUED_COLOR }} />
                    <td>
                      <span>Issued</span>
                    </td>
                    <td>
                      <span>{ sfLicenseIssuedCount}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: AVAILABLE_COLOR }} />
                    <td>
                      <span>Available</span>
                    </td>
                    <td>
                      <span>{sfLicenseAvailableCount}</span>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <p className="align-center">{`Salesforce License Usage for ${config.tenant.code.toUpperCase()} ${config.environment}`}</p>
            </div>
          </div>
        )}
        {typeof sfIsConfigured === 'boolean' && !sfIsConfigured && (
          <div>Salesforce is not configured for this tenant.</div>
        )}
        {callingApi && (
          <ScreenPreLoader style={{position: 'absolute', top: '0', right: '0', left: '0', bottom: '0', height: '500px'}} />
        )}
      </div>
    )
  }
  dismissInactiveRoleNotification = () => {
    const {
      nonDismissedExpiredUserRoleIds,
      setUserRolesDismissed,
      userAccount,
      accessToken,
      uuid
    } = this.props
    _.forEach(nonDismissedExpiredUserRoleIds, nonDismissedExpiredUserRoleId => {
      const fetchEndpoint = userRoleDismissInactiveNotifyEndpoint.replace(
        '{userRoleId}',
        nonDismissedExpiredUserRoleId
      )
      this.props.adminFetch(fetchEndpoint, {
          method: 'PUT'
        })
        .then(json => {})
        .catch(error => {
          console.error(
            'Layout dismissInactiveRoleNotification failed at endpoint ' +
              fetchEndpoint +
              ' with error:',
            error
          )
          const code = CODES.SELECT_ROLE_DISMISS_INACTIVE_NOTIFY
          if (shouldThrow(code)) {
            this.setState(() => {
              if (error instanceof IEServiceError) {
                throw error
              } else {
                throw new AdminPortalException(error, code)
              }
            })
          }
        })
    })
    setUserRolesDismissed(nonDismissedExpiredUserRoleIds)
  }
}

function mapStateToProps(state) {
  let activeEntitlements = []
  if (state.userAccess && state.userAccess.userRoles) {
    _.forEach(state.userAccess.userRoles, userRole => {
      _.forEach(userRole.entitlements, entitlement => {
        activeEntitlements.push(entitlement.entitlementName)
      })
    })
    activeEntitlements = _.uniq(activeEntitlements)
  }
  const nonDismissedExpiredUserRoleIds = (
    _.get(state.userAccess, 'inactiveUserRoles') || []
  )
    .filter(
      inactiveUserRole =>
        'Y' !== inactiveUserRole['dismissInactiveNotifyFlag'] &&
        'URURAM07111' !== _.get(inactiveUserRole, 'adjustMethod.key')
    )
    .map(inactiveUserRole => inactiveUserRole['userRoleId'])

  let uuid = _.get(state.auth, 'userAccount.uuid')
  let accessToken = _.get(state.auth, 'accessToken')

  return {
    activeEntitlements,
    uuid,
    nonDismissedExpiredUserRoleIds,
    authHeader: accessToken ? 'Bearer ' + accessToken : ''
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setUserRolesDismissed: dismissedUserRoleIds =>
      dispatch(actions.setUserRolesDismissed(dismissedUserRoleIds)),
     redirect: path => {
        dispatch(actions.redirect(path))
     }
  }
}

export default withAdminFetcher(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
)

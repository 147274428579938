import { includes } from 'lodash'
const _ = { includes }

export default function errors(
  state = {
    errorList: [],
    errorDesc: ""
  },
  action
) {
  switch (action.type) {
    case 'SET_ERROR_LIST':
      const newErrorList = action.errorList || []
      return {
        ...state,
        errorList: newErrorList
      }
    case 'SHOW_ERROR_MODAL':
      const errorDesc = action.errorDesc
      return {
        ...state,
        errorDesc: errorDesc
      }
    default:
      return state
  }

}


import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import auth from './auth'
import userAccess from './userAccess'
import { i18nReducer } from 'react-redux-i18n'
import errors from './errors'

export default combineReducers({
  auth,
  userAccess,
  router: routerReducer,
  i18n: i18nReducer,
  errors
})

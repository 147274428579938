import * as queryString from 'query-string'
import LocalStorage from '@optum-wvie/dynamic-ui-framework/src/localStorage'

const tenantConfig = {
  WV: {
    id: 1,
    code: 'WV',
    name: 'West Virginia',
    fetcher: {
      retries: 5,
      timeoutMs: 30000
    }
  }
}

function determineTenant(environment) {
  // Always returning WV
  return 'WV'

}

export default function configureTenant(environment, tenant) {
  const resolvedTenant = tenant ? tenant : determineTenant(environment)
  const tenantLSKey = 'IE-' + environment + '-ADMIN-TENANT'

  if (resolvedTenant && tenantConfig.hasOwnProperty(resolvedTenant)) {
    LocalStorage.setItem(tenantLSKey, resolvedTenant)
    return tenantConfig[resolvedTenant]
  } else {
    const defaultTenantConfig = tenantConfig['default']
    console.error(
      'Unrecognized tenant:',
      resolvedTenant,
      'using default config',
      defaultTenantConfig
    )
    LocalStorage.setItem(tenantLSKey, defaultTenantConfig.code)
    return defaultTenantConfig
  }
}

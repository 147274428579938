import { includes } from 'lodash'
const _ = { includes }

export default function userAccess(
  state = {
    isFetching: false,
    userRoles: null,
    selectedUserRole: null,
    inactiveUserRoles: null,
    userAccount: null
  },
  action
) {
  switch (action.type) {
    case 'INITIALIZE':
      return Object.assign({}, state, {
        isFetching: false
      })
    case 'LOGIN_REQUEST':
      return Object.assign({}, state, {
        userRoles: null,
        selectedUserRole: false
      })
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, {
        isFetching: true
      })
    case 'LOGOUT_SUCCESS':
      return Object.assign({}, state, {
        isFetching: false,
        userRoles: null,
        selectedUserRole: false
      })
    case 'UPDATE_ACCESS_ROLES':
      return Object.assign({}, state, {
        isFetching: false,
        userRoles: action.userRoles,
        inactiveUserRoles: action.inactiveUserRoles,
        userAccount: action.userAccount
      })
    case 'UPDATE_SELECTED_ROLE':
      return Object.assign({}, state, {
        selectedUserRole: action.selectedUserRole
      })
    case 'DISMISS_USER_ROLE_INACTIVE_NOTIFY':
      return Object.assign({}, state, {
        inactiveUserRoles: (state.inactiveUserRoles || []).map(
          inactiveUserRole => {
            if (
              (_.includes(action.dismissedUserRoleIds),
              inactiveUserRole['userRoleId'])
            ) {
              return {
                ...inactiveUserRole,
                dismissInactiveNotifyFlag: 'Y'
              }
            } else {
              return inactiveUserRole
            }
          }
        )
      })
    default:
      return state
  }
}

import * as React from 'react'
import { connect } from 'react-redux'
import * as actions from "../redux/actions"

class LandingPage extends React.Component<any, any>{
  constructor(props) {
    super(props)

    this.state = {
      classes: ""
    }

  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.forceUpdate()
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {
      this.forceUpdate()
    })
  }

  fadeDown = () => {
    this.setState({
      classes: "animated fadeOutDown"
    })
  }

  goToLogin = () => {
    this.props.redirect("/login")
  }

  render() {

    const height = window.innerHeight + "px"
    const width = window.innerWidth + "px"

    const { classes } = this.state

    return (
      <main role="main" className="landing-page">
        <div>
          <h1 className="sr-only">Login</h1>
        </div>
        <div className="bg" style={{ height, width }} />
        <div className={"container " + classes}>
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">IE Admin Portal</h1>
              <button
                className="btn btn-primary btn-lg btn-block btn-login"
                style={{ marginBottom: "10px" }}
                onClick={this.goToLogin}
              >
                Click Here to Login
          </button>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return ({
    redirect: (path) => {
      dispatch(actions.redirect(path))
    }
  })
}

export default connect(
  null,
  mapDispatchToProps
)(LandingPage)
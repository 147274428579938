import { get } from 'lodash'

const _ = { get }

export default function auth(
  state = {
    idToken: null,
    accessToken: null,
    isFetching: false,
    isAuthenticated: false,
    errorMessage: null,
    userAccount: null,
    code: null,
    expiry: null,
    timeout: null,
    skipNextRefresh: null
  },
  action
) {
  switch (action.type) {
    case 'INITIALIZE':
      return Object.assign({}, state, {
        isFetching: false
      })
    case 'LOGIN_REQUEST':
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        code: action.code
      })
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, {
        idToken: action.idToken,
        accessToken: action.accessToken,
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
        userAccount: action.userAccount,
        expiry: action.expiry,
        timeout: action.timeout,
        skipNextRefresh: true
      })
    case 'LOGIN_FAILURE':
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      })
    case 'LOGOUT_SUCCESS':
      return Object.assign({}, state, {
        idToken: null,
        accessToken: null,
        isAuthenticated: false,
        code: null,
        userAccount: null,
        expiry: null,
        timeout: null,
        skipNextRefresh: null,
        isFetching: false
      })
    case 'LOGIN_REFRESH_LOCAL':
      return Object.assign({}, state, {
        isFetching: false,
        expiry: action.expiry,
        timeout: action.timeout
      })
    case 'CLEAR_SKIP_NEXT_REFRESH':
      return Object.assign({}, state, {
        skipNextRefresh: false
      })
    case 'LOGIN_REFRESH_INITIAL':
      return Object.assign({}, state, {
        isFetching: true
      })
    case 'LOGIN_REFRESH':
      return Object.assign({}, state, {
        isFetching: false,
        idToken: action.idToken,
        accessToken: action.accessToken,
        expiry: action.expiry,
        timeout: action.timeout
      })
    default:
      return state
  }
}

import * as React from 'react'
import { config } from '~/config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-shared/src/utils'
import { ErrorBoundary } from '../../Errors'
import { AccessManagementContainerProps } from './AccessManagement'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const AccessManagementContainer = MultitenantLoadable(
  () => import(/* webpackChunkName: "AccessManagementContainer" */ './AccessManagementContainer'),
  () =>
    import(/* webpackChunkName: "[request]" */ 
      '../../' +
        tenantDirectoryName +
        '/AccessManagement/AccessManagementContainer'
    )
)

const AccessManagementPresentation = MultitenantLoadable(
  () => import(/* webpackChunkName: "AccessManagementPresentation" */ './AccessManagementPresentation'),
  () =>
    import(/* webpackChunkName: "[request]" */ 
      '../../' +
        tenantDirectoryName +
        '/AccessManagement/AccessManagementPresentation'
    )
)

export default function AccessManagement(
  props: AccessManagementContainerProps
) {
  return (
    <ErrorBoundary name="AccessManagement">
      <AccessManagementContainer
        {...props}
        presentation={presentationProps => (
          <AccessManagementPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

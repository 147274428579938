import * as React from 'react'
import {
  IEServiceError,
  NetworkConnectionError,
  InternalServerError
} from '@optum-wvie/dynamic-ui-shared/src/utils'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { config } from '~/config'
import ErrorPopup from '@optum-wvie/dynamic-ui-shared/components/Errors/ErrorPopup'

const PREFIX = 'IE'
const MODULE = 'ADMIN'

export const CATEGORIES = {
  //Project wide categories:
  FUNCTIONAL_LEVEL: '00A', //TODO: What functionality?
  INVALID_INPUT_PARAMETERS: '00G',
  INTERNAL_SERVER_ERROR: '00H',
  NETWORK_CONNECTION_ERROR: '00I',
  OTHER: '00Z',

  //All known exception types in JavaScript:
  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Error_types
  JS_EVAL_ERROR: 'JS1',
  JS_INTERNAL_ERROR: 'JS2',
  JS_RANGE_ERROR: 'JS3',
  JS_REFERENCE_ERROR: 'JS4',
  JS_SYNTAX_ERROR: 'JS5',
  JS_TYPE_ERROR: 'JS6',
  JS_URI_ERROR: 'JS7'
}

function getCategoryByErrorType(error) {
  if (error instanceof NetworkConnectionError) {
    return CATEGORIES.NETWORK_CONNECTION_ERROR
  } else if (error instanceof InternalServerError) {
    return CATEGORIES.INTERNAL_SERVER_ERROR
  } else if (error instanceof EvalError) {
    return CATEGORIES.JS_EVAL_ERROR
  } else if (error instanceof RangeError) {
    return CATEGORIES.JS_RANGE_ERROR
  } else if (error instanceof ReferenceError) {
    return CATEGORIES.JS_REFERENCE_ERROR
  } else if (error instanceof SyntaxError) {
    return CATEGORIES.JS_SYNTAX_ERROR
  } else if (error instanceof TypeError) {
    return CATEGORIES.JS_TYPE_ERROR
  } else if (error instanceof URIError) {
    return CATEGORIES.JS_URI_ERROR
  } else {
    return CATEGORIES.OTHER
  }
}

export const CODES = {
  ROOT_UNKNOWN: 'RT0',

  ATTRIBUTE_MANAGEMENT_UNKNOWN: 'AT0',
  ATTRIBUTE_MANAGEMENT_SEARCH_ATTRS: 'AT1',
  ATTRIBUTE_MANAGEMENT_FORMS: 'AT2',

  ACCESS_MANAGEMENT_UNKNOWN: 'AM0',
  ACCESS_MANAGEMENT_ALL_ROLES: 'AM1',
  ACCESS_MANAGEMENT_ALL_ENTITLEMENTS: 'AM2',
  ACCESS_MANAGEMENT_ALL_ORGS: 'AM3',
  ACCESS_MANAGEMENT_SEARCH_USERS: 'AM4',
  ACCESS_MANAGEMENT_DELETE_ROLE: 'AM5',
  ACCESS_MANAGEMENT_MODIFY_ENTITLEMENT: 'AM6',
  ACCESS_MANAGEMENT_DELETE_ENTITLEMENT: 'AM7',
  ACCESS_MANAGEMENT_USER_INFO: 'AM8',
  ACCESS_MANAGEMENT_ALL_COUNTIES: 'AM9',

  ENTITLEMENT_MANAGEMENT_UNKNOWN: 'EM0',
  ENTITLEMENT_MANAGEMENT_FORMS: 'EM1',
  ENTITLEMENT_MANAGEMENT_ENTITLEMENTS_TO_ROLES: 'EM2',
  ENTITLEMENT_MANAGEMENT_ALL_ENTITLEMENTS: 'EM3',
  ENTITLEMENT_MANAGEMENT_ASSOCIATED_ROLES: 'EM4',

  EXPORT_REPORT_UNKNOWN: 'ER0',
  EXPORT_REPORT_ROLES_ENTITLEMENTS_FORM: 'ER1',
  EXPORT_REPORT_SEARCH_ROLES_ENTITLEMENTS: 'ER2',
  EXPORT_REPORT_USERS_ROLES_FORM: 'ER3',
  EXPORT_REPORT_SEARCH_USERS_ROLES: 'ER4',

  INVITATIONS_MANAGEMENT_UNKNOWN: 'IM0',
  INVITATIONS_MANAGEMENT_SEND_INVITATIONS: 'IM1',
  INVITATIONS_MANAGEMENT_BULK_FORM: 'IM2',
  INVITATIONS_MANAGEMENT_FILE_UPLOAD: 'IM3',
  INVITATIONS_MANAGEMENT_INVITATION_FORMS: 'IM4',
  INVITATIONS_MANAGEMENT_BULK_FILE_STATUSES: 'IM5',
  INVITATIONS_MANAGEMENT_GET_INVITATIONS: 'IM6',
  INVITATIONS_MANAGEMENT_CANCEL_INVITATION: 'IM7',
  INVITATIONS_MANAGEMENT_BULK_FILE_STATUS: 'IM8',
  INVITATIONS_MANAGEMENT_USER_CONFLICT: 'IM9',

  ORGANIZATION_MANAGEMENT_UNKNOWN: 'OM0',
  ORGANIZATION_MANAGEMENT_SEARCH_ORGS: 'OM1',
  ORGANIZATION_MANAGEMENT_FORMS: 'OM2',
  ORGANIZATION_MANAGEMENT_GET_ORGS: 'OM3',
  ORGANIZATION_MANAGEMENT_EDIT_ORGS: 'OM4',
  ORGANIZATION_MANAGEMENT_SAVE_ORGS: 'OM5',
  ORGANIZATION_MANAGEMENT_INACTIVATE_USER_SEARCH: 'OM6',
  ORGANIZATION_MANAGEMENT_INACTIVATE_COUNTY_SEARCH: 'OM7',
  ORGANIZATION_MANAGEMENT_GET_ORG_RULE: 'OM8',

  ROLE_INFORMATION_UNKNOWN: 'RI0',
  ROLE_INFORMATION_FORMS: 'RI1',

  ROLE_ORGANIZATION_UNKNOWN: 'RO0',
  ROLE_ORGANIZATION_FORMS: 'RO1',
  ROLE_ORGANIZATION_SEARCH_ORG_BY_ROLE: 'RO2',

  ROLES_MANAGEMENT_UNKNOWN: 'RM0',
  ROLES_MANAGEMENT_CREATE_ROLE: 'RM1',
  ROLES_MANAGEMENT_FORMS: 'RM2',
  ROLES_MANAGEMENT_FETCH_ASSIGNED_ENTITLEMENTS: 'RM3',
  ROLES_MANAGEMENT_UPDATE_ROLE_INFO: 'RM4',
  ROLES_MANAGEMENT_ADD_ROLE: 'RM5',
  ROLES_MANAGEMENT_REMOVE_ROLE: 'RM6',
  ROLES_MANAGEMENT_UPDATE_ROLE: 'RM7',

  ROLE_USERS_UNKNOWN: 'RU0',
  ROLE_USERS_FORMS: 'RU1',
  ROLE_USERS_SEARCH_USERS_ORGS: 'RU2',
  ROLE_USERS_SEARCH_USERS: 'RU3',
  ROLE_USERS_ASSIGNED_USERS: 'RU4',

  USER_ROLE_UNKNOWN: 'UR0',
  USER_ROLE_FORMS: 'UR1',
  USER_ROLE_SAVE: 'UR2',
  USER_ROLE_ADDITIONAL_TAB_DATA: 'UR3',

  USER_SEARCH_UNKNOWN: 'US0',
  USER_SEARCH_FORMS: 'US1',

  COUNTIES_MANAGEMENT_UNKNOWN: 'CM0',
  COUNTIES_MANAGEMENT_FORMS: 'CM1',
  COUNTIES_MANAGEMENT_SEARCH: 'CM2',
  COUNTIES_MANAGEMENT_ASSIGN_ORG: 'CM3',
  COUNTIES_MANAGEMENT_DELETE_ASSIGNMENT: 'CM4',

  MASSCHANGE_SELECTION_CRITERIA: 'MC1',

  SELECT_ROLE_UNKNOWN: 'SR0',
  SELECT_ROLE_UPDATE_DEFAULT_ROLE: 'SR1',
  SELECT_ROLE_DISMISS_INACTIVE_NOTIFY: 'SR2',

  BROADCAST_MESSAGE_FORMS: 'BM0',
  BROADCAST_MESSAGE_LIST: 'BM1',
  BROADCAST_MESSAGE_ALL_ROLES: 'BM2',
  BROADCAST_MESSAGE_SEND: 'BM3',
  BROADCAST_MESSAGE_GET_MESSAGE: 'BM4',
  BROADCAST_MESSAGE_UPDATE_MESSAGE: 'BM5',

  MESSAGE_CENTER_FORMS: 'MC0',
  MESSAGE_CENTER_LIST: 'MC1',
  MESSAGE_CENTER_CREATE: 'MC2',
  MESSAGE_CENTER_GET_MESSAGE: 'MC3',
  MESSAGE_CENTER_UPDATE_MESSAGE: 'MC4',
  MESSAGE_CENTER_DELETE_MESSAGE: 'MC5',

  LOGIN_HISTORY_FORMS: 'LH1',
  LOGIN_HISTORY_FETCH: 'LH2',

  STATUS_HISTORY_FORMS: 'SH1',
  STATUS_HISTORY_FETCH: 'SH2',

  ROLE_HISTORY_FORMS: 'RH1',
  ROLE_HISTORY_FETCH: 'RH2',

  CASE_AUDIT_FORMS: 'CA1',
  CASE_AUDIT_SEARCH: 'CA2',
}

function getDefaultCodeByPageName(name) {
  switch (name) {
    case 'Root':
      return CODES.ROOT_UNKNOWN
    case 'AccessManagement':
      return CODES.ACCESS_MANAGEMENT_UNKNOWN
    case 'EntitlementsManagement':
      return CODES.ENTITLEMENT_MANAGEMENT_UNKNOWN
    case 'Export':
      return CODES.EXPORT_REPORT_UNKNOWN
    case 'InvitationsManagement':
      return CODES.INVITATIONS_MANAGEMENT_UNKNOWN
    case 'OrganizationsManagement':
      return CODES.ORGANIZATION_MANAGEMENT_UNKNOWN
    case 'RoleInformation':
      return CODES.ROLE_INFORMATION_UNKNOWN
    case 'RoleOrganizations':
      return CODES.ROLE_ORGANIZATION_UNKNOWN
    case 'RolesManagement':
      return CODES.ROLES_MANAGEMENT_UNKNOWN
    case 'RoleUsers':
      return CODES.ROLE_USERS_UNKNOWN
    case 'UserRole':
      return CODES.USER_ROLE_UNKNOWN
    case 'UserSearch':
      return CODES.USER_SEARCH_UNKNOWN
    case 'CountiesManagement':
      return CODES.COUNTIES_MANAGEMENT_UNKNOWN
  }
}

export function shouldThrow(code) {
  //This will act as our "fusebox" for now. It will return whether an error is critical
  //enough that it should be thrown to trigger the ErrorBoundary component, based on the code.
  switch (code) {
    case CODES.ACCESS_MANAGEMENT_DELETE_ROLE:
    case CODES.ACCESS_MANAGEMENT_MODIFY_ENTITLEMENT:
    case CODES.ACCESS_MANAGEMENT_DELETE_ENTITLEMENT:
    case CODES.INVITATIONS_MANAGEMENT_FILE_UPLOAD:
    case CODES.ROLES_MANAGEMENT_ADD_ROLE:
    case CODES.ROLES_MANAGEMENT_REMOVE_ROLE:
    case CODES.ROLES_MANAGEMENT_UPDATE_ROLE:
    case CODES.INVITATIONS_MANAGEMENT_SEND_INVITATIONS:
      return false
    default:
      return true
  }
}

export function AdminPortalException(error, code, category = null) {
  this.message = error.message
  this.error = error
  this.code = code
  if (category) {
    this.category = category
  } else {
    this.category = getCategoryByErrorType(error)
  }
  if ('captureStackTrace' in Error) {
    Error.captureStackTrace(this)
  } else {
    const newError = new (Error as any)()
    this.stack = newError.stack
  }
}
AdminPortalException.prototype = Object.create(Error.prototype)
AdminPortalException.prototype.name = 'AdminPortalException'
AdminPortalException.prototype.constructor = AdminPortalException

class ErrorBoundaryComponent extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorObject: undefined,
      errorCode: undefined,
      correlationId: null
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(errorObject: any, errorInfo: any) {
    let errorCode = ''
    let correlationId = null
    if (errorObject instanceof IEServiceError) {
      //The error was received from a backend service API response.
      const { errorList, businessCorrelationId } = errorObject as any
      //TODO: Can there be multiple errors in errorList? Do we display all?
      errorCode = get(errorList, '[0].errorCode') || ''
      correlationId = businessCorrelationId
    } else if (errorObject instanceof AdminPortalException) {
      //The error originated from a known point in the UI.
      const { category, code } = errorObject as any
      errorCode = `${PREFIX}-${MODULE}-${category}${code}`
    } else {
      //It is unclear where the error came from. Check for JS exceptions and specify the affected component.
      errorCode = `${PREFIX}-${MODULE}-`
      errorCode += getCategoryByErrorType(errorObject)
      errorCode += getDefaultCodeByPageName(this.props.name)
    }
    console.error(
      'ErrorBoundary caught exception! errorCode:',
      errorCode,
      'errorObject',
      errorObject,
      'errorInfo',
      errorInfo
    )
    //TODO: Call front end service logging API?
    this.setState({
      hasError: true,
      errorCode,
      errorObject,
      errorInfo,
      correlationId
    })
  }

  render() {
    const { children, name, locale } = this.props
    const {
      hasError,
      errorCode,
      errorObject,
      correlationId
    } = this.state

    if (hasError) {
      return <ErrorPopup
        message={"An Error Has Occurred"}
        supportEmail={get(config, 'supportContact.email')}
        supportContactNo={get(config, 'supportContact.phone')}
        correlationId={correlationId}
        errorCode={errorCode}
        errorDescription={get(errorObject, 'errorList[0].errorDescription')}
      />
    }

    return children
  }
}

function mapStateToProps(state) {
  return {}
}

export const ErrorBoundary = connect(mapStateToProps)(ErrorBoundaryComponent)

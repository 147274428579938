import { includes } from 'lodash'

const assetBase = process.env.ASSET_BASE
const _ = { includes }

export function isImplemented() {
  ///TODO: In West Virginia's implementation fork, return true here.
  return true
}

export function getConfig(baseConfig: any) {
  let config: any = {
    tenant: {
      id: 1,
      code: 'wv',
      state: 'wv',
      name: 'West Virginia',
      fetcher: {
        retries: 5,
        timeoutMs: 30000
      },
      timeZone: 'America/New_York',
      assignmentStrategy: 'PROGRAM',
      isUpdateLockedUserInfoEnabled: true
    },
    childSupportUrl: '',
    childWelfareUrl: '',
    disableOrgSelectForRoles: [
      'CLP_CW_STAFF',
      'CLP_CW_SUPERVISOR',
      'CLP Flexi',
      'BCF Finance',
      'Administrator',
      'Child Welfare'
    ],
    supportContact: {
      phone: '844-414-5112',
      email: 'wvtcc@optum.com'
    },
    enableNonCWFeatures: true,
    endpoint: {
      submitIncident: '/services/wv/self-service-portal/incident/create'
    }
  }

  //Retrieve environment-level config.
  switch (baseConfig.environment) {
    case 'LOCAL':
      config = {
        ...config,
        childSupportUrl: 'https://wvie-cs-test.optum.com/oscar/auth',
        childWelfareUrl: 'https://wvie-childwelfare-test.optum.com',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: null,
        matomoCustomDimensionCorrelationId: null,
        schedulingAlertsGetUrl: 'https://wvie-apigw-test-dmz.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-apigw-test-dmz.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        sendEmailInvitationUrl: 'https://wvie-apigw-test-dmz.optum.com/services/esb/mule/framework/v2.0/notification/email',
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null,
        enableNonCWFeatures: true,
        gatewayWvUrl: 'https://wvie-api-gw2.optum.com',
        invitationEmailSubject: 'Action Required: WV PATH Optum GovID Invitation',
        //"gatewayBaseUrl": "https://wv-ie-api-gw-tst.optum.com" //TODO
      }
      break
    case 'WVDEV':
      config = {
        ...config,
        tCode: 'wv',
        formsBaseUrl:
          'https://wvie-apigw-dev-dmz.optum.com',
        basePath: '',
        domainUrl: 'https://wv-admin-dev.optum.com',
        openId_loginUrl:
        'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwv-admin-dev.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
        isProd: true,
        childSupportUrl: 'https://wvie-cs-dev.optum.com/oscar',
        childWelfareUrl: 'https://wvie-childwelfare-dev.optum.com/cw',      
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',  
        matomoSiteId: 35,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://wvie-apigw-dev-dmz.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-apigw-dev-dmz.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        sendEmailInvitationUrl: 'https://wvie-apigw-dev-dmz.optum.com/services/esb/mule/framework/v2.0/notification/email',
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null,
        enableNonCWFeatures: true,
        gatewayWvUrl: 'https://wvie-api-gw-dev.optum.com',
        //"gatewayBaseUrl": "https://wv-ie-api-gw-dev.optum.com" //TODO
      }
      break
    case 'WVTEST':      
      config = {
        ...config,
        tCode: 'wv',
        formsBaseUrl:
          'https://wvie-apigw-test-dmz.optum.com',
        basePath: '',
        domainUrl: 'https://wv-admin-test.optum.com',
        openId_loginUrl:
        'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwv-admin-test.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
        isProd: true,        
        "gatewayBaseUrl": "https://hcck8s-ie-apigw-tst.optum.com",
        childSupportUrl: 'https://wvie-cs-test.optum.com/oscar',
        childWelfareUrl: 'https://wvie-childwelfare-test.optum.com/cw',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 41,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://wvie-apigw-test-dmz.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-apigw-test-dmz.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        sendEmailInvitationUrl: 'https://wvie-apigw-test-dmz.optum.com/services/esb/mule/framework/v2.0/notification/email',
        isAuditingServiceEnabled: true,
        enableNonCWFeatures: true,
        gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
        invitationEmailSubject: 'Action Required: WV PATH NonOFE STS Optum GovID Invitation',
        //logoutRedirectUrl: 'https://wvie-clpcs-test.optum.com/'
        //"gatewayBaseUrl": "https://wv-ie-api-gw-tst.optum.com" //TODO
      }
      break
    case 'STAGING':
      config = {
        ...config,
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://wvie-api-gw-sit1.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-api-gw-sit1.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null,
        enableNonCWFeatures: true
        //"gatewayBaseUrl": "https://wv-ie-api-gw-stg.optum.com" //TODO
      }
      break
    case 'DEMO':
      config = {
        ...config,
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://wvie-api-gw-sit1.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-api-gw-sit1.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null,
        enableNonCWFeatures: true
        //"gatewayBaseUrl": "https://wv-ie-api-gw-demo.optum.com" //TODO
      }
      break
    case 'OS-DEV':
      config = {
        ...config,
        childSupportUrl: 'https://wvie-cs-dev.optum.com/oscar/auth',
        childWelfareUrl: 'https://localhost:8443/cw/openid_connect_login',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 35,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://clientscheduling-wvie-test.ocp-ctc-dmz-nonprod.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://clientscheduling-wvie-test.ocp-ctc-dmz-nonprod.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null,
        enableNonCWFeatures: true
      }
      break
    case 'OS-TST':
      config = {
        ...config,
        childSupportUrl: 'https://wvie-cs-test.optum.com/oscar/auth',
        childWelfareUrl: 'https://wvie-childwelfare-test.optum.com',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 41,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://clientscheduling-wvie-test.ocp-ctc-dmz-nonprod.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://clientscheduling-wvie-test.ocp-ctc-dmz-nonprod.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        isAuditingServiceEnabled: true,
        enableNonCWFeatures: true
        //logoutRedirectUrl: 'https://wvie-clpcs-test.optum.com/'
      }
      break
    case 'OS-STG':
      config = {
        ...config,
        childSupportUrl: 'https://wvie-cs-stage.optum.com/oscar/auth',
        childWelfareUrl: 'https://wvie-cw-demo-uci.optum.com/cw/openid_connect_login',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: '',
        schedulingAlertsPostUrl: '',
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null,
        enableNonCWFeatures: true
      }
      break
    case 'OFEDEV':
      config = {
        ...config,
        tenant:{
          ...config.tenant,
          id: 1,
          code:'wv'
        },
        formsBaseUrl: 'https://wvie-api-gw-dev2.optum.com',
        basePath: '',
        domainUrl: 'https://wvie-admin-dev.optum.com/',
        gatewayBaseUrl: 'https://ie-api-gw-int1.optum.com',
        openId_loginUrl:
        'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-admin-dev.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
        isProd: true,
        childSupportUrl: 'https://wvie-childsupport-dev.optum.com/oscar/auth',
        childWelfareUrl: 'https://wvie-childwelfare-dev.optum.com',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 25,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://wvie-api-gw-dev.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-api-gw-dev.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        sendEmailInvitationUrl: 'https://wvie-api-gw-dev.optum.com/services/esb/mule/framework/v2.0/notification/email',
        isAuditingServiceEnabled: true,
        enableNonCWFeatures: true,
        gatewayWvUrl: 'https://wvie-api-gw-dev.optum.com',
        //logoutRedirectUrl: 'https://wvie-clp-dev.optum.com/'
      }
      break
      case 'OFETST':
      config = {
        ...config,
        tenant:{
          ...config.tenant,
          id: 1,
          code:'wv'
        },
        formsBaseUrl: 'https://wvie-api-gw-test2.optum.com',
        openId_loginUrl:
        'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-admin-test.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
        isProd: true,
        gatewayBaseUrl: 'https://ie-api-gw-test.optum.com',
        basePath: '',
        domainUrl: 'https://wvie-admin-test.optum.com/',
        childSupportUrl: 'https://wvie-cs-test.optum.com/oscar',
        childWelfareUrl: 'https://wvie-childwelfare-test.optum.com/cw',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 9,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://wvie-api-gw-test.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-api-gw-test.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        sendEmailInvitationUrl: 'https://wvie-api-gw-test.optum.com/services/esb/mule/framework/v2.0/notification/email',
        isAuditingServiceEnabled: true,
        enableNonCWFeatures: true,
        gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
        invitationEmailSubject: 'Action Required: WV PATH OFE TST Optum GovID Invitation',
        //logoutRedirectUrl: 'https://wvie-clp-test.optum.com/'
      }
      break
      case 'OFE-SIT':
      config = {
        ...config,
        tenant:{
          ...config.tenant,
          id: 1,
          code:'wv'
        },
        formsBaseUrl: 'https://wvie-api-gw-sit2.optum.com',
        openId_loginUrl:
        'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-admin-sit1.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
        isProd: true,
        gatewayBaseUrl: 'https://ie-api-gw-sit.optum.com',
        basePath: '',
        domainUrl: 'https://wvie-admin-sit1.optum.com/',
        childSupportUrl: 'https://wvie-childsupport-sit1.optum.com/oscar',
        childWelfareUrl: 'https://wvie-childwelfare-sit1.optum.com/cw',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 15,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://wvie-api-gw-test.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-api-gw-test.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        sendEmailInvitationUrl: 'https://wvie-api-gw-sit1.optum.com/services/esb/mule/framework/v2.0/notification/email',
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: 'https://wvie-clp-sit1.optum.com/',
        enableNonCWFeatures: true,
        emailFormat: /^(?!\.)[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@wv\.gov$/,
        gatewayWvUrl: 'https://wvie-api-gw-sit1.optum.com',
        invitationEmailSubject: 'Action Required: WV PATH SIT Optum GovID Invitation',
      }
      break
      case 'OFE-TTSIT':
      config = {
        ...config,
        tenant:{
          ...config.tenant,
          id: 1,
          code:'wv'
        },
        formsBaseUrl: 'https://wvie-api-gw-ttsit.optum.com',
        openId_loginUrl:
        'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-admin-ttsit.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
        isProd: true,
        gatewayBaseUrl: 'https://ie-api-gw-ttsit.optum.com',
        basePath: '',
        domainUrl: 'https://wvie-admin-ttsit.optum.com/',
        childSupportUrl: 'https://wvie-childsupport-ttsit.optum.com/oscar',
        childWelfareUrl: 'https://wvie-childwelfare-ttsit.optum.com/cw',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 48,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://wvie-api-gw-test.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-api-gw-test.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        sendEmailInvitationUrl: 'https://wvie-api-gw-ttsit.optum.com/services/esb/mule/framework/v2.0/notification/email',
        isAuditingServiceEnabled: true,
        enableNonCWFeatures: true,
        //logoutRedirectUrl: 'https://wvie-clp-ttsit.optum.com/',
        gatewayWvUrl: 'https://wvie-api-gw-ttsit.optum.com',
        invitationEmailSubject: 'Action Required: WV PATH TTSIT Optum GovID Invitation',
      }
      break
    case 'OFE-UAT':
    config = {
      ...config,
      tenant:{
        ...config.tenant,
        id: 1,
        code:'wv'
      },
      formsBaseUrl: 'https://wvie-api-gw-uat2.optum.com',
      openId_loginUrl: 'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-admin-uat.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
      isProd: true,
      gatewayBaseUrl: 'https://ie-api-gw-uat.optum.com',
      basePath: '',
      domainUrl: 'https://wvie-admin-uat.optum.com/',
      childSupportUrl: 'https://wvie-childsupport-uat.optum.com/oscar',
      childWelfareUrl: 'https://wvie-childwelfare-uat.optum.com/cw',
      matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
      matomoSiteId: 20,
      matomoCustomDimensionGovIdUsername: 1,
      matomoCustomDimensionCorrelationId: 2,
      schedulingAlertsGetUrl: 'https://wvie-api-gw-uat.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
      schedulingAlertsPostUrl: 'https://wvie-api-gw-uat.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
      sendEmailInvitationUrl: 'https://wvie-api-gw-uat.optum.com/services/esb/mule/framework/v2.0/notification/email',
      isAuditingServiceEnabled: true,
      logoutRedirectUrl: 'https://wvie-clp-uat.optum.com/',
      enableNonCWFeatures: true,
      gatewayWvUrl: 'https://wvie-api-gw-uat.optum.com',
      invitationEmailSubject: 'Action Required: WV PATH UAT Optum GovID Invitation',
      }
      break
    case 'OFE-PRF':
      config = {
        ...config,
        tenant:{
          ...config.tenant,
          id: 1,
          code:'wv'
        },
        formsBaseUrl: 'https://wvie-api-gw-perf-stg2.optum.com',
        openId_loginUrl: 'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-admin-perf-stg.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
        isProd: true,
        gatewayBaseUrl: 'https://ie-api-gw-perf.optum.com',
        basePath: '',
        domainUrl: 'https://wvie-admin-perf-stg.optum.com/',
        childSupportUrl: 'https://wvie-childsupport-perf-stg.optum.com/oscar',
        childWelfareUrl: 'https://wvie-childwelfare-perf-stg.optum.com/cw',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://wvie-api-gw-perf-stg.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-api-gw-perf-stg.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        sendEmailInvitationUrl: 'https://wvie-api-gw-perf-stg.optum.com/services/esb/mule/framework/v2.0/notification/email',
        isAuditingServiceEnabled: false,
        logoutRedirectUrl: null,
        enableNonCWFeatures: true,
        gatewayWvUrl: 'https://wvie-api-gw-perf-stg.optum.com',
        invitationEmailSubject: 'Action Required: WV PATH Performance Optum GovID Invitation',
      }
      break
    case 'OFE-STAGE':
      config = {
        ...config,
      tenant:{
        ...config.tenant,
        id: 1,
        code:'wv'
      },
      formsBaseUrl: 'https://wvie-api-gw-stage2.optum.com',
      openId_loginUrl: 'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-admin-stage.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
      isProd: true,
      gatewayBaseUrl: 'https://ie-api-gw-stage.optum.com',
      basePath: '',
      domainUrl: 'https://wvie-admin-stage.optum.com/',
      childSupportUrl: 'https://wvie-childsupport-stage.optum.com/oscar',
      childWelfareUrl: 'https://wvie-childwelfare-stage.optum.com/cw',
      matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
      matomoSiteId: 51,
      matomoCustomDimensionGovIdUsername: 1,
      matomoCustomDimensionCorrelationId: 2,
      schedulingAlertsGetUrl: 'https://wvie-api-gw-stage.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
      schedulingAlertsPostUrl: 'https://wvie-api-gw-stage.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
      sendEmailInvitationUrl: 'https://wvie-api-gw-stage.optum.com/services/esb/mule/framework/v2.0/notification/email',
      isAuditingServiceEnabled: true,
      logoutRedirectUrl: 'https://wvie-clp-stage.optum.com/',
      invitationEmailSubject: 'Action Required: WV PATH Training Optum GovID Invitation',
      enableNonCWFeatures: true,
      gatewayWvUrl: 'https://wvie-api-gw-stage.optum.com',
      }
      break
    case 'OFE-MPROD':
      config = {
        ...config,
        tenant:{
          ...config.tenant,
          id: 1,
          code:'wv'
        },
        formsBaseUrl: 'https://wvie-api-gw2-mprod.optum.com',
        //openId_loginUrl: 'https://identity.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-admin.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
        openId_loginUrl: 'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-admin-mprod.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
        isProd: true,
        gatewayBaseUrl: 'https://ie-api-gw-mprod.optum.com',
        basePath: '',
        domainUrl: 'https://wvie-admin-mprod.optum.com/',//'https://wvie-admin.optum.com/',
        childSupportUrl: 'https://wvie-childsupport-mprod.optum.com/oscar',
        childWelfareUrl: 'https://wvie-childwelfare-mprod.optum.com/cw',
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 54,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://wvie-api-gw-mprod.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-api-gw-mprod.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        sendEmailInvitationUrl: 'https://wvie-api-gw-mprod.optum.com/services/esb/mule/framework/v2.0/notification/email',
        isAuditingServiceEnabled: true,
        enableNonCWFeatures: true,
        gatewayWvUrl: 'https://wvie-api-gw-mprod.optum.com',
        logoutRedirectUrl: 'https://wvie-clp-mprod.optum.com/', //'https://wvie-clp.optum.com/'
        invitationEmailSubject: 'Action Required: WV PATH MockProd Optum GovID Invitation',
      }
      break
    case 'OFE-PROD':
      config = {
        ...config,
        tenant:{
          ...config.tenant,
          id: 1,
          code:'wv'
        },
        formsBaseUrl: 'https://wvie-api-gw2.optum.com',
        //openId_loginUrl: 'https://identity.optumgovid.com/oidc/authorize?client_id=IEAdminWV&response_type=code&redirect_uri=https%3A%2F%2Fwvie-admin.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
        openId_loginUrl: 'https://identity.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fadminportal.wvpath.wv.gov%2Flogin&scope=openid+profile+email&state={state}&nonce=',
        isProd: true,
        gatewayBaseUrl: 'https://ie-api-gw2.optum.com',
        basePath: '',
        domainUrl: 'https://adminportal.wvpath.wv.gov/',//'https://wvie-admin.optum.com/',
        childSupportUrl: 'https://wvie-childsupport.optum.com/oscar/auth',
        childWelfareUrl: 'https://wvie-childwelfare.optum.com',
        matomoUrl: null, // TODO
        matomoSiteId: null, // TODO
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        schedulingAlertsGetUrl: 'https://wvie-api-gw2.optum.com/WVIE/clientscheduling/v1.0/generateSchedUserAlert',
        schedulingAlertsPostUrl: 'https://wvie-api-gw2.optum.com/WVIE/clientscheduling/v1.0/updateSchedUserAlert',
        sendEmailInvitationUrl: 'https://wvie-api-gw2.optum.com/services/esb/mule/framework/v2.0/notification/email',
        isAuditingServiceEnabled: true,
        enableNonCWFeatures: false,
        gatewayWvUrl: 'https://wvie-api-gw2.optum.com',
        logoutRedirectUrl: 'https://agencyportal.wvpath.wv.gov',//'https://wvie-clp.optum.com/'
        invitationEmailSubject: 'Action Required: WV PATH Production Optum GovID Invitation',
        removeCCAndCSLOB: false
      }
      break
    default:
      config = {
        ...config,
        matomoUrl: null,
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        invitationEmailSubject: 'Action Required: WV PATH Optum GovID Invitation',
        //"gatewayBaseUrl": "https://wv-ie-api-gw-dev.optum.com" //TODO
      }
  }

  return config
}

export function determineEnvironment() {
  const urlHost = new URL(window.location.href).host;
  switch (urlHost) {
    case "localhost":
      return "LOCAL";
    case "wv-admin-dev.optum.com":
      return "WVDEV";
    case "wv-admin-test.optum.com":
      return "WVTEST";
    case "wvie-admin-dev.optum.com":
      return "OFEDEV";
    case "wvie-admin-test.optum.com":
      return "OFETST";
    case "wvie-admin-sit1.optum.com":
      return "OFE-SIT";
    case "wvie-admin-ttsit.optum.com":
      return "OFE-TTSIT";
    case "wvie-admin-uat.optum.com":
      return "OFE-UAT";
    case "wvie-admin-stage.optum.com":
      return "OFE-STAGE";
    case "wvie-admin-perf-stg.optum.com":
      return "OFE-PRF";
    case "wvie-admin-mprod.optum.com":
      return "OFE-MPROD";
    case "adminportal.wvpath.wv.gov":
      return "OFE-PROD";
    default:
      return null;
  }
}

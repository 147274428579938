import { includes, merge, forEach } from 'lodash'
import * as queryString from 'query-string'
import LocalStorage from '@optum-wvie/dynamic-ui-framework/src/localStorage'
import {
    isImplemented as isImpl_WV,
    getConfig as getConfig_WV,
    determineEnvironment as getEnv_WV
} from './config_WV'

import {
    isImplemented as isImpl_MN,
    getConfig as getConfig_MN,
    determineEnvironment as getEnv_MN
} from './config_MN'

const TENANTS = {
    wv: {
        isImpl: isImpl_WV,
        getEnv: getEnv_WV,
        getConfig: getConfig_WV
    },
    mn: {
        isImpl: isImpl_MN,
        getEnv: getEnv_MN,
        getConfig: getConfig_MN
    }
}

//END TENANT CONFIG SECTION
//////////////////////////////////////////////////////////////////////////////////////

let environment: string = null
let tenantCode: string = null
let buildType: string = 'Core'

forEach(TENANTS, (value, key) => {
    if (value.isImpl()) {
        //We are in an implementation build.
        tenantCode = key
        environment = value.getEnv()
        buildType = 'Product'
        return false
    }
})

if (!environment) {
    environment = determineBaseEnvironment()
}

if (!tenantCode) {
    tenantCode = determineBaseTenant(environment)
    LocalStorage.setItem(`IE-${environment}-CP-TENANT`, tenantCode)
}

const getTenantConfig = TENANTS[tenantCode].getConfig

export let config = initConfig()

function initConfig() {
    //Retrieve common config first.
    let preConfig = {
        environment,
        basePath: '',
        domainUrl: '',
        gatewayBaseUrl: '',
        formsBaseUrl: '',
        sendEmailInvitationUrl: '',
        assets: {
            path: '/assets',
            logo: {
                path: '/logo/logo.png',
                alt: 'Minnesota Logo'
            }
        },
        endpoint: {
            base: {
                userManagementPath: '/api/ie/usrmgmt/v2',
                authorizationPath: '/api/ie/usrmgmt/v2/authorize',
                formPath: '/api/ie/forms/{tenantCode}/v2.0',
                invitationPath: '/api/ie/usrmgmt/v2',
                batchPath: '/api/ie/batch/v1.0',
                workloadManagementPath: '/api/ie/workloadmgmt/v1',
                domainPath: '/api/ie/domain/v2',
                referenceDataPath:'/api/ie/referencedata/v2'
            },
            signIn: '{gatewayBaseUrl}/api/ie/adminPortal/v1.0/Master/auth/signIn',
            roles: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/roles',
            orgs: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/orgs',
            entitlements:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/entitlements',
            entitlementsRoles:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/roles/entitlements',
            deleteRole:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/roles/{roleId}',
            searchUsers:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/search',
            userRolesReport:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/roles/report',
            roleChangeHistory:
                '{gatewayBaseUrl}/api/ie/auditing/v1/{tenantCode}/roleChangeHistory/',
            rolesEntitlementsReport:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/roles/entitlements/report',
            userAccount:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/{uuid}',
            userStatus:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/status',
            userInfo:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/lock',
            updateDefaultUserRole:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/user-roles/{userRoleId}/default/{isDefaultUserRole}',
            userRoles:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/{userId}/roles',
            userRolesDeleteEndpoint:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/{userId}/deleteRoles',
            userRolesFromOrg:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/{userId}/orgs/{orgId}/roles',
            userRolesSaveUpdate:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/{userId}/roles',
            getUserRoles:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/{uuid}/roles',
            getRoleUsers:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/roles/{roleName}',
            getRoleUsersFromOrg:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/orgs/{orgId}/roles/{roleName}',
            addRoleUsers:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/roles/{roleName}',
            addRoleUsersFromOrg:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/orgs/{orgId}/roles/{roleName}',
            removeRoleUsers:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/roles/{roleName}/revoke',
            removeRoleUsersFromOrg:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/users/orgs/{orgId}/roles/{roleName}/revoke',
            getRoleEntitlements:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/roles/{roleId}/entitlements',
            updateEntitlement:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/entitlements',
            updateRoleEntitlements:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/roles/{roleName}/entitlements',
            saveEntitlementToRoles:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/entitlements/{entitlementName}/roles',
            authorizationTokenEndpoint:
                '{gatewayBaseUrl}{authorizationPath}/{tenantCode}/token-v2?code={authorizationCode}',
            authorizationUserinfoEndpoint:
                '{gatewayBaseUrl}{authorizationPath}/{tenantCode}/userinfo-v2',
            refreshTokenEndpoint:
                '{gatewayBaseUrl}{authorizationPath}/{tenantCode}/token/refresh',
            singleSignOn:
                '{gatewayBaseUrl}{authorizationPath}/{tenantCode}/sso',
            addOrg: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/orgs',
            getOrg: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/orgs/{orgId}',
            editOrg: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/orgs/{orgId}',
            searchOrgs: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/orgs',
            allOrgs: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/all-orgs',
            getOrgHistory: '{gatewayBaseUrl}/api/ie/auditing/v1/{tenantCode}/orgChangeHistory/',
            searchAttrs: '{gatewayBaseUrl}{workloadManagementPath}/{tenantId}/attributes',
            invitationReport: '{formsBaseUrl}{formPath}/adm/InvitationsReport',
            searchInvitationReport: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/invitations/report',
            searchInvitationReportBulk: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/invitations/bulk/report',
            searchCounties:
                '{gatewayBaseUrl}{userManagementPath}/{tenantId}/counties',
            searchRegionCounties:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/counties/region',                
            assignCountyToOrg:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/orgs/counties/assignments',
            assignCountyToIndividualOrg:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/orgs/counties/{countyId}',
            deleteCountyOrgAssignment:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/orgs/counties/{countyId}',
            createInvite: '{gatewayBaseUrl}{invitationPath}/{tenantCode}/invitations',
            getInvite: '{gatewayBaseUrl}{invitationPath}/{tenantCode}/invitations',
            cancelInvite:
                '{gatewayBaseUrl}{invitationPath}/{tenantCode}/invitations/{invitationToken}/portal/{portalId}',
            updateInvite: '{gatewayBaseUrl}{invitationPath}/{tenantCode}/invitations',
            getBulkFilesStatus: '{gatewayBaseUrl}{invitationPath}/{tenantCode}/invitations/bulk',
            uploadBulkFile:
                '{gatewayBaseUrl}{invitationPath}/{tenantCode}/invitations/bulk',
            updateActionNeeded:
                '{gatewayBaseUrl}{invitationPath}/{tenantCode}/invitations/bulk/{batchId}/action/{actionneeded}',
            invitations: '{formsBaseUrl}{formPath}/adm/Invitations',
            bulkInvitations: '{formsBaseUrl}{formPath}/adm/BulkInvitations',
            entitlementsManagement:
                '{formsBaseUrl}{formPath}/adm/EntitlementsManagement',
            organizations: '{formsBaseUrl}{formPath}/adm/Organizations',
            attributes: '{formsBaseUrl}{formPath}/adm/Attributes',
            roleInformation: '{formsBaseUrl}{formPath}/adm/RoleInformation',
            rolesManagement: '{formsBaseUrl}{formPath}/adm/RolesManagement',
            accessManagement: '{formsBaseUrl}{formPath}/adm/AccessManagement',
            countiesManagement: '{formsBaseUrl}{formPath}/adm/CountiesManagement',
            userRole: '{formsBaseUrl}{formPath}/adm/UserRole',
            rolesAndEntitlementsReport:
                '{formsBaseUrl}{formPath}/adm/RolesAndEntitlementsReport',
            usersAndRolesReport: '{formsBaseUrl}{formPath}/adm/UsersAndRolesReport',
            roleChangeReport: '{formsBaseUrl}{formPath}/adm/RoleChangeReport',
            adminStaticTranslation: '{formsBaseUrl}{formPath}/adm/translations',
            sharedStaticTranslation: '{formsBaseUrl}{formPath}/shared/translations',
            massChangeSelectionCriteria:
                '{formsBaseUrl}{formPath}/adm/MassChangeSelectionCriteria',
            validateBatchName:
                '{gatewayBaseUrl}{batchPath}/{tenantId}/masschange/reqparam/{batchName}/exists',
            viewBatchConfiguration:
                '{gatewayBaseUrl}{batchPath}/{tenantId}/masschange/reqparam',
            removeBatchSelectionCriteria:
                '{gatewayBaseUrl}{batchPath}/{tenantId}/batch/masschange/reqparam/{massChgReqParmId}',
            saveBatchSelectionCriteria:
                '{gatewayBaseUrl}{batchPath}/{tenantId}/batch/masschange/reqparam',
            viewBatchSkipConfiguration:
                '{gatewayBaseUrl}{batchPath}/{tenantId}/masschange/skipconfig',
            removeBatchSkipCriteria:
                '{gatewayBaseUrl}{batchPath}/{tenantId}/masschange/skipconfig/{skipConfigurationId}',
            massChangeSkipCriteria:
                '{formsBaseUrl}{formPath}/adm/MassChangeSkipCriteria',
            saveBatchSkipCriteria:
                '{gatewayBaseUrl}{batchPath}/{tenantId}/masschange/skipconfig',
            apLicenseInfo:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/salesforce/licenses',
            userRoleDismissInactiveNotify:
                '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/user-roles/{userRoleId}/messages/dismiss',
            revokeTokenEndpoint:
                '{gatewayBaseUrl}{authorizationPath}/{tenantCode}/token/revoke',
            searchAssignmentRuleEndpoint:
                '{gatewayBaseUrl}{workloadManagementPath}/{tenantId}/assignmentrule',
            getAssignmentRuleEndpoint:
                '{gatewayBaseUrl}{workloadManagementPath}/{tenantId}/assignmentrule/{assignmentRuleId}',
            saveAssignmentRuleEndpoint:
                '{gatewayBaseUrl}{workloadManagementPath}/{tenantId}/assignmentrule',
            deleteAssignmentRuleEndpoint:
                '{gatewayBaseUrl}{workloadManagementPath}/{tenantId}/assignmentrule/{assignmentRuleId}',
            assignmentRuleEligibleOrgsEndpoint:
                '{gatewayBaseUrl}{workloadManagementPath}/{tenantId}/assignmentrule/organizations',
            assignmentRuleUserEndpoint:
                '{gatewayBaseUrl}{workloadManagementPath}/{tenantId}/assignmentrule/user/',
            rebalanceAssignmentRuleEndpoint:
                '{gatewayBaseUrl}{workloadManagementPath}/{tenantId}/assignmentrule/rebalance/{assignmentRuleId}',
            assignmentRulesManagement:
                '{formsBaseUrl}{formPath}/shared/AssignmentRulesManagement',
            saveAssignmentRulePriorityEndpoint:
                '{gatewayBaseUrl}{workloadManagementPath}/{tenantId}/assignmentrule/updaterulepriority',
            getOrgWorkersEndpoint:
                '{gatewayBaseUrl}{workloadManagementPath}/{tenantId}/organization/{orgId}/users',
            broadcast: '{formsBaseUrl}{formPath}/adm/Broadcast',
            searchBroadcastMessages: '{gatewayBaseUrl}{domainPath}/{tenantCode}/broadcasts',
            broadcastMessage: '{gatewayBaseUrl}{domainPath}/{tenantCode}/broadcasts/messages',
            getMyBroadcasts: '{gatewayBaseUrl}{domainPath}/{tenantCode}/broadcasts/messages',
            getBroadcastMessage: '{gatewayBaseUrl}{domainPath}/{tenantCode}/broadcasts/{broadcastId}',
            updateBroadcastMessage: '{gatewayBaseUrl}{domainPath}/{tenantCode}/broadcasts/{broadcastId}',
            dismissMyBroadcastMessage: '{gatewayBaseUrl}{domainPath}/{tenantCode}/broadcasts/{broadcastId}/messages/dismiss',
            messages: '{formsBaseUrl}{formPath}/adm/Messages',
            message: '{gatewayBaseUrl}{domainPath}/{tenantCode}/admin-messages',
            messageId: '{gatewayBaseUrl}{domainPath}/{tenantCode}/admin-messages/{adminMessageId}',
            messageSearch: '{gatewayBaseUrl}{domainPath}/{tenantCode}/admin-messages/search',
            userAccess: '{formsBaseUrl}{formPath}/shared/UserAccess',
            userAccessRequest: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/access-requests',
            userAccessRequests: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/access-requests',
            userAccessRequestsSearch: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/access-requests/search',
            accessRequest: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/access-requests/{accessRequestId}',
            userAccountUserAccess: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/access-requests/accessrequest/useraccountlookupdetail/{userId}',
            userAccountUserAccessSave: '{gatewayBaseUrl}{userManagementPath}/{tenantCode}/access-requests/accessrequest/useraccountlookupdetail/',
            workerMessagesConfig: '{formsBaseUrl}{formPath}/shared/WorkerMessagesConfig',
            workerMessagesAssign: '{formsBaseUrl}{formPath}/shared/WorkerMessagesAssign',
            getWorkerMessage: '{gatewayBaseUrl}{domainPath}/{tenantCode}/worker-messages/task-configuration',
            updateWorkerMessage: '{gatewayBaseUrl}{domainPath}/{tenantCode}/worker-messages/task-configuration',
            workerMessageAssignment: '{gatewayBaseUrl}{domainPath}/{tenantCode}/worker-messages/assignment',
            loginHistory: '{formsBaseUrl}{formPath}/adm/LoginHistory',
            userLoginHistory: '{gatewayBaseUrl}/api/ie/auditing/v1/{tenantCode}/userLoginHistory/',
            statusHistory: '{formsBaseUrl}{formPath}/adm/StatusHistory',
            userStatusHistory: '{gatewayBaseUrl}/api/ie/auditing/v1/{tenantCode}/userStatusHistory/',
            roleHistory: '{formsBaseUrl}{formPath}/adm/RoleHistory',
            userRoleHistory: '{gatewayBaseUrl}/api/ie/auditing/v1/{tenantCode}/userRoleChangeHistory/',
            workloadRules: '{formsBaseUrl}{formPath}/adm/WorkloadRules',
            getErrorList: '{gatewayBaseUrl}{referenceDataPath}/{tenantCode}/errors/codes',
            getCaseCountForOrgs: '{gatewayBaseUrl}{domainPath}/{tenantCode}/cases/orgs/search',
            getCaseCountForWorkers: '{gatewayBaseUrl}{domainPath}/{tenantCode}/cases/users/search',
            accountLookup: '{gatewayBaseUrl}/api/ie/usrmgmt/v2/{tenantCode}/access-requests/accessrequest/useraccountlookupdetail/{uuid}',
            incidentForm: '{formsBaseUrl}/api/ie/forms/wv/3.0/clp/IncidentForm'
        },
        buildType,
        openId_loginUrl: '',
        openId_localhostAccount: {
            userId: 'TesterUserId',
            uuid: 'b27fe984-e1eb-4c91-b0a9-150d9dca72b1',
            firstName: 'TestFirstName',
            lastName: 'TestLastName',
            email: 'test@gmail.com',
            defaultUserRoleId: '825',
            activeStatusFlag: true
        },
        portalName: 'adp',
        defaultExpiryMs: 15 * 60000,
        countdownTimerMs: 5 * 60000,
        default: {
            pagination: {
                pageIndex: 1,
                recordsPerPage: 20
            }
        },
        invitationEmailSubject: "Invitation URL has been sent, Please Review"
    }

    let bases = {}

    switch (environment) {
        case 'LOCAL':
            bases = {
                domainUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
                //gatewayBaseUrl: "http://localhost:8080",
                gatewayBaseUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
                openId_loginUrl: '/fake-login',
                //formsBaseUrl: 'https://ie-apigw-tst.optum.com',
                //"formsBaseUrl": "http://localhost:7070",
                "formsBaseUrl": "https://wvie-apigw-test-dmz.optum.com",
                isProd: false
            }
            break

        case 'OS-DEV':
            bases = {
                gatewayBaseUrl: 'https://hcck8s-ie-apigw-dev.optum.com',
                gatewayWvUrl: 'https://wvie-api-gw-dev.optum.com',
                formsBaseUrl: 'https://hcck8s-ie-apigw-dev.optum.com',
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-admin-dev.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-admin-dev.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: true,
                sendEmailInvitationUrl: 'https://email-notification-mule-wvie-dev-k8s-ctc-dmz-nonprod.optum.com'
            }
            break

        case 'OS-TST':
            bases = {
                gatewayBaseUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
                gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
                formsBaseUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-admin-tst.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-admin-tst.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: true,
                sendEmailInvitationUrl: 'https://email-notification-mule-wvie-test-k8s-ctc-dmz-nonprod.optum.com'
            }
            break

        case 'OS-STG':
            bases = {
                gatewayBaseUrl: 'https://ie-apigw-stg.optum.com',
                formsBaseUrl: 'https://ie-apigw-stg.optum.com',
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-admin-stg.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-admin-stg.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: true
            }
            break

        //HCCK8S
        case 'K8SDEV':
            bases = {
                gatewayBaseUrl: 'https://hcck8s-ie-apigw-dev.optum.com',
                formsBaseUrl: 'https://hcck8s-ie-apigw-dev.optum.com',
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-admin-dev-nofe.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-admin-dev-nofe.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: true
            }
            break

        case 'K8STST':
            bases = {
                gatewayBaseUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
                formsBaseUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-admin-tst-nofe.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-admin-tst-nofe.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: true
            }
            break

        case 'K8SSTG':
            bases = {
                gatewayBaseUrl: 'https://hcck8s-ie-apigw-stg.optum.com',
                formsBaseUrl: 'https://hcck8s-ie-apigw-stg.optum.com',
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-admin-stg-nofe.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-admin-stg-nofe.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: true
            }
            break

        //HCCK8S
        case 'OS-DEMO':
            bases = {
                gatewayBaseUrl: 'https://ie-apigw-demo.optum.com',
                formsBaseUrl: 'https://ie-apigw-demo.optum.com/api/ie/forms/{tenantCode}/v1.0',
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-admin-demo.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-admin-demo.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: true
            }
            break

        case 'OFE-DEV':
            bases = {
                basePath: '',
                domainUrl: 'https://ie-admin-dev.optum.com',
                gatewayWvUrl: 'https://wvie-api-gw-dev.optum.com',
                gatewayBaseUrl: 'https://ie-api-gw-int1.optum.com',
                formsBaseUrl: 'https://ie-api-gw-int1.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-admin-dev.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: false
            }
            break

        case 'OFE-TEST':
            bases = {
                basePath: '',
                gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
                domainUrl: 'https://ie-admin-test.optum.com',
                gatewayBaseUrl: 'https://ie-api-gw-test.optum.com',
                formsBaseUrl: 'https://ie-api-gw-test.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-admin-test.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: false
            }
            break

        case 'OFE-SIT':
            bases = {
                basePath: '',
                gatewayWvUrl: 'https://wvie-api-gw-test.optum.com',
                domainUrl: 'https://ie-admin-test.optum.com',
                gatewayBaseUrl: 'https://ie-api-gw-test.optum.com',
                formsBaseUrl: 'https://ie-api-gw-test.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-admin-test.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: false
            }
            break

        case 'OFE-UAT':
            bases = {
                basePath: '',
                domainUrl: 'https://ie-admin-uat.optum.com',
                gatewayBaseUrl: 'https://ie-api-gw-uat.optum.com',
                formsBaseUrl: 'https://ie-api-gw-uat.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-admin-uat.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: false
            }
            break

        case 'OFE-STAGE':
            bases = {
                basePath: '',
                domainUrl: 'https://ie-admin-stage.optum.com',
                gatewayBaseUrl: 'https://ie-api-gw-stage.optum.com',
                formsBaseUrl: 'https://ie-api-gw-stage.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-admin-stage.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: true
            }
            break

        case 'OFE-PROD':
            bases = {
                basePath: '',
                domainUrl: 'https://ie-admin.optum.com',
                gatewayBaseUrl: 'https://ie-api-gw.optum.com',
                formsBaseUrl: 'https://ie-api-gw.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-admin.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: true
            }
            break

        case 'DEV':
            bases = {
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-adm-dev.optum.com',
                gatewayBaseUrl: 'https://hcck8s-ie-apigw-dev.optum.com',
                formsBaseUrl: 'https://hcck8s-ie-apigw-dev.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-adm-dev.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                sendEmailInvitationUrl: 'https://email-notification-mule-wvie-test-k8s-ctc-dmz-nonprod.optum.com',

                isProd: false
            }
            break

        case 'TEST':
            bases = {
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-adm-tst.optum.com',
                gatewayBaseUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
                formsBaseUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-adm-tst.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: false
            }
            break

        case 'STAGING':
            bases = {
                gatewayBaseUrl: 'https://ie-api-gw-stg.optum.com',
                formsBaseUrl: 'https://ie-api-gw-stg.optum.com',
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-adm-stg.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-adm-stg.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: true
            }
            break

        case 'DEMO':
            bases = {
                gatewayBaseUrl: 'https://ie-api-gw-demo.optum.com',
                formsBaseUrl: 'https://ie-api-gw-demo.optum.com',
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-adm-demo.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-adm-demo.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: false
            }
            break

        case 'OS-PRF':
            bases = {
                gatewayBaseUrl: 'https://ie-apigw-prf.optum.com',
                formsBaseUrl: 'https://ie-apigw-prf.optum.com',
                basePath: '',
                domainUrl: 'https://ie-{tenantCode}-admin-prf.optum.com',
                openId_loginUrl:
                    'https://identity.nonprod.optumgovid.com/oidc/authorize?client_id=IEAdmin&response_type=code&redirect_uri=https%3A%2F%2Fie-{tenantCode}-admin-prf.optum.com%2Flogin&scope=openid+profile+email&state={state}&nonce=',
                isProd: true
            }
            break
            
        default:
            bases = {
                domainUrl: 'https://hcck8s-ie-apigw-dev.optum.com',
                gatewayBaseUrl: 'https://hcck8s-ie-apigw-dev.optum.com',
                formsBaseUrl: 'https://hcck8s-ie-apigw-dev.optum.com',
                sendEmailInvitationUrl: 'https://email-notification-mule-wvie-dev.ocp-ctc-dmz-nonprod.optum.com'
            }
    }

    preConfig = merge({}, preConfig, bases)

    let finalConfig = null
    if (getTenantConfig) {
        const tenantConfig = getTenantConfig(preConfig)
        //Merge the tenant configuration after base, so it will take priority.
        finalConfig = merge({}, preConfig, tenantConfig)
    } else {
        finalConfig = preConfig
    }

    let endpoints = finalConfig.endpoint

    Object.keys(endpoints['base']).forEach(key => {
        endpoints['base'][key] = endpoints['base'][key]
            .replace('{tenantId}', finalConfig.tenant.id)
            .replace('{tenantCode}', finalConfig.tenant.code)
    })

    Object.keys(preConfig).forEach(key => {
        if (typeof finalConfig[key] === 'string') {
            finalConfig[key] = finalConfig[key]
                .replace('{tenantId}', finalConfig.tenant.id)
                .replace('{tenantCode}', finalConfig.tenant.code)
        }
    })

    Object.keys(endpoints).forEach(key => {
        if (typeof endpoints[key] === 'string') {
            endpoints[key] = endpoints[key]
                .replace('{gatewayBaseUrl}', finalConfig['gatewayBaseUrl'])
                .replace('{formsBaseUrl}', finalConfig['formsBaseUrl'])
                .replace('{sendEmailInvitationUrl}', finalConfig['sendEmailInvitationUrl'])
                .replace('{userManagementPath}', endpoints.base['userManagementPath'])
                .replace('{authorizationPath}', endpoints.base['authorizationPath'])
                .replace('{referenceDataPath}', endpoints.base['referenceDataPath'])
                .replace('{batchPath}', endpoints.base['batchPath'])
                .replace('{invitationPath}', endpoints.base['invitationPath'])
                .replace('{workloadManagementPath}', endpoints.base['workloadManagementPath'])
                .replace('{domainPath}', endpoints.base['domainPath'])
                .replace('{formPath}', endpoints.base['formPath'])
                .replace('{tenantId}', finalConfig.tenant.id)
                .replace('{tenantCode}', finalConfig.tenant.code)
        }
    })

    return finalConfig
}

function determineBaseTenant(environment) {
    //We simulating tenant in the core product. Try to determine tenant in the following order, taking the first resolved.
    //0. Check if we're in OFE, if so, we only have MN
    //1. URL prefix
    //2. URL "tenant" query parameter
    //3. Last known tenant for this environment, saved to local storage.

    const { url, query } = queryString.parseUrl(window.location.href)

    // In OFE we no longer care about the implementation details of the WV portals; we only have 1 tenant and it's MN
    const baseEnv = determineBaseEnvironment().toLocaleUpperCase()
    if (
        baseEnv == 'OFEDEV' ||
        baseEnv == 'OFETST' ||
        baseEnv == 'OFESTG' ||
        baseEnv.substr(0, 3) == 'OFE'
    )
        return 'wv'

    //Determine the tenant based on the URL prefix
    const prefixMatch = url
        .toLowerCase()
        .match('^(?:(?:http|https)://)ie-([a-zA-Z][a-zA-Z])-')
    if (prefixMatch && prefixMatch[1]) {
        return prefixMatch[1].toLowerCase()
    }

    //Determine the tenant based on the tenant URL parameter
    if (query && query.tenant) {
        return query.tenant.toLowerCase()
    }

    //Determine the tenant based on what was last saved to local storage.
    let localStorageTenant = LocalStorage.getItem(`IE-${environment}-ADM-TENANT`)
    if (localStorageTenant && localStorageTenant.length > 0) {
        return localStorageTenant.toLowerCase()
    }

    //Default to MN if somehow we have not determined the tenant.
    return 'wv'
}

function determineBaseEnvironment() {
  const urlHost = new URL(window.location.href).host;
  switch (urlHost) {
    case "localhost":
      return "LOCAL";
    case "ie-mn-admin-dev-nofe.optum.com":
      return "DEV";
    case "ie-mn-admin-tst-nofe.optum.com":
      return "TEST";
    case "ie-mn-admin-prf-nofe.optum.com":
      return "PERF";
    case "wv-admin-dev.optum.com":
      return "WVDEV";
    case "wv-admin-test.optum.com":
      return "WVTEST";
    case "wvie-admin-dev.optum.com":
      return "OFEDEV";
    case "wvie-admin-test.optum.com":
      return "OFETST";
    case "wvie-admin-sit1.optum.com":
      return "OFE-SIT";
    case "wvie-admin-ttsit.optum.com":
      return "OFE-TTSIT";
    case "wvie-admin-uat.optum.com":
      return "OFE-UAT";
    case "wvie-admin-stage.optum.com":
      return "OFE-STAGE";
    case "wvie-admin-perf-stg.optum.com":
      return "OFE-PRF";
    case "wvie-admin-mprod.optum.com":
      return "OFE-MPROD";
    case "adminportal.wvpath.wv.gov":
      return "OFE-PROD";
    default:
      return "LOCAL";
  }
}
const jwt_decode = require('jwt-decode')
import { config } from '~/config'
import { push } from 'react-router-redux'
import { get, pick, isNil, find, merge, throttle, some, isPlainObject } from 'lodash'
import { loadTranslations } from 'react-redux-i18n'
import { _moment } from '@optum-wvie/dynamic-ui-framework/src/utils'
import { fetchJson, fetchFullJson, IEServiceError } from '@optum-wvie/dynamic-ui-shared/src/utils'
import { CATEGORIES, ENTITLEMENTS } from '@optum-wvie/dynamic-ui-shared/src/entitlements'
import { trackAPICall } from '~/src/utils'

const _ = { get, pick, isNil, find, merge, throttle, some, isPlainObject }

declare var Promise: any

const basePath = config.basePath
const authorizationTokenUrl = config['endpoint']['authorizationTokenEndpoint']
const authorizationUserinfoUrl =
  config['endpoint']['authorizationUserinfoEndpoint']
const refreshTokenUrl = config['endpoint']['refreshTokenEndpoint']
const revokeTokenUrl = config['endpoint']['revokeTokenEndpoint']
const getErrorListUrl = config['endpoint']['getErrorList']

//Call refresh API no more than once per five minutes.
const REFRESH_AUTH_FREQUENCY_MS = 5 * 60000

export function fakeLoginUser(role) {
  return dispatch => {
    const userAccount = config.openId_localhostAccount
    dispatch(fakeLogin())
    dispatch(
      setupUserAccess(fakeUserRoles[role], fakeInactiveRoles[role], userAccount)
    )
    dispatch(push(basePath + '/doLogin'))
  }
}
export function getErrorList(
  errorList
) {
  return {
    type: 'GET_ERROR_LIST',
    errorList
  }
}

export function setErrorList(errorList) {
  return {
    type: 'SET_ERROR_LIST',
    errorList
  }
}

export function showErrorModal(errorDesc) {
  return {
    type: 'SHOW_ERROR_MODAL',
    errorDesc
  }
}

export function loginUser(code, state, redirectUri) {
  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(code))
    const tokenEndpoint =
      authorizationTokenUrl.replace('{authorizationCode}', code) +
      `&state=${state}&redirectUri=${encodeURIComponent(redirectUri)}`
    const tokenRequest = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        portalName: config.portalName,
        tenantCode: config.tenant.code
      }
    }
    let beforeMillis = new Date().valueOf()
    return fetchFullJson(tokenEndpoint, tokenRequest, 0, 60000)
      .then(tokenResponse => {
        let durationMillis = new Date().valueOf() - beforeMillis
        if (_.isPlainObject(tokenResponse) && tokenResponse.businessCorrelationId) {
          trackAPICall(tokenEndpoint, tokenRequest, tokenResponse.businessCorrelationId, true, durationMillis)
        } else {
          trackAPICall(tokenEndpoint, tokenRequest, null, true, durationMillis)
        }

        //Decode and parse the JWT
        const idToken = tokenResponse.data.id_token
        const accessToken = tokenResponse.data.access_token
        const jwt = jwt_decode(idToken)
        let userAccount = {
          uuid: jwt['sub'] || null,
          userId: jwt['preferred_username'] || null,
          firstName: jwt['given_name'] || null,
          lastName: jwt['family_name'] || null,
          dob: jwt['birthdate'] || null,
          email: jwt['email'] || null,
          defaultUserRoleId: null,
          activeStatusFlag: null
        }

        let errorList = []
        const timeout = tokenResponse.data.expires_in * 1000
        //const timeout = 4 * 60 * 60 * 1000  // Increased timeout for demo purpose.

        const expiry = _moment(config).valueOf() + timeout

        // Calling Update User Account
        const userInfoEndpoint = authorizationUserinfoUrl
        const userInfoRequest = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            uuid: jwt['sub'],
            portalName: config.portalName,
            Authorization: 'Bearer ' + accessToken,
            tenantCode: config.tenant.code
          },
          mode: 'cors'
        }
        beforeMillis = new Date().valueOf()
        fetchFullJson(userInfoEndpoint, userInfoRequest, 0, 60000)
          .then(userInfoResponse => {
            durationMillis = new Date().valueOf() - beforeMillis            
            if (_.isPlainObject(userInfoResponse) && userInfoResponse.businessCorrelationId) {
              trackAPICall(userInfoEndpoint, userInfoRequest, userInfoResponse.businessCorrelationId, true, durationMillis)
            } else {
              trackAPICall(userInfoEndpoint, userInfoRequest, null, true, durationMillis)
            }

            userAccount = _.get(userInfoResponse.data, 'userInformation', {})
            dispatch(
              setupUserAccess(
                _.get(userInfoResponse.data, 'userRoles', []),
                _.get(userInfoResponse.data, 'inactiveUserRoles', []),
                userAccount
              )
            )
            dispatch(push(basePath + '/dashboard'))
            return true
          })
          .catch(ex => {
            durationMillis = new Date().valueOf() - beforeMillis      
            console.error('loginUser update account action failed with ex', ex)
            if (ex instanceof IEServiceError) {
              const { businessCorrelationId } = ex as any
              trackAPICall(userInfoEndpoint, userInfoRequest, businessCorrelationId, false, durationMillis)
            } else {
              trackAPICall(userInfoEndpoint, userInfoRequest, null, false, durationMillis)
            }
          })

        // Dispatch the success action
        dispatch(
          receiveLogin(userAccount, idToken, accessToken, expiry, timeout)
        )
         // Calling get error details API
        const errorListEndpoint = getErrorListUrl
        const errorListRequest = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            uuid: jwt['sub'],
            portalName: config.portalName,
            Authorization: 'Bearer ' + accessToken,
            tenantCode: config.tenant.code
          },
          mode: 'cors'
        }
        const errorListBeforeMillis = new Date().valueOf()
        fetchFullJson(errorListEndpoint, errorListRequest, 0, 60000)
          .then(errorListResponse => {
            const errorListDurationMillis = new Date().valueOf() - errorListBeforeMillis            
            if (_.isPlainObject(errorListResponse) && errorListResponse.businessCorrelationId) {
              trackAPICall(errorListEndpoint, errorListRequest, errorListResponse.businessCorrelationId, true, errorListDurationMillis)
            } else {
              trackAPICall(errorListEndpoint, errorListRequest, null, true, errorListDurationMillis)
            }

            dispatch(
              setErrorList(
                errorListResponse.data || []
              )
            )
            return true
          })
          .catch(ex => {
            const errorListDurationMillis = new Date().valueOf() - errorListBeforeMillis    
            console.error('get error list action failed with ex', ex)
            if (ex instanceof IEServiceError) {
              const { businessCorrelationId } = ex as any
              trackAPICall(errorListEndpoint, errorListRequest, businessCorrelationId, false, errorListDurationMillis)
            } else {
              trackAPICall(errorListEndpoint, errorListRequest, null, false, errorListDurationMillis)
            }
          })
      })
      .catch(err => {
        console.error('loginUser action failed with ex', err)
        dispatch(loginError(err.message))
        let durationMillis = new Date().valueOf() - beforeMillis
        if (err instanceof IEServiceError) {
          const { businessCorrelationId } = err as any
          trackAPICall(tokenEndpoint, tokenRequest, businessCorrelationId, false, durationMillis)
        } else {
          trackAPICall(tokenEndpoint, tokenRequest, null, false, durationMillis)
        }
        return Promise.reject(err.message)
      })
  }
}

export function requestLogin(code) {
  return {
    type: 'LOGIN_REQUEST',
    isFetching: true,
    isAuthenticated: false,
    code
  }
}

export function receiveLogin(
  userAccount,
  idToken,
  accessToken,
  expiry,
  timeout
) {
  return {
    type: 'LOGIN_SUCCESS',
    isFetching: false,
    isAuthenticated: true,
    userAccount,
    idToken,
    accessToken,
    expiry,
    timeout
  }
}

const refreshAuthService = _.throttle((dispatch, skipNextRefresh, fetcher, isInitial) => {
  if (skipNextRefresh) {
    dispatch({
      type: 'CLEAR_SKIP_NEXT_REFRESH'
    })
  } else {
    if (isInitial) {
      dispatch({
        type: 'LOGIN_REFRESH_INITIAL'
      })
    }
    fetcher(refreshTokenUrl, {
      method: 'POST',
      body: '{}'
    })
      .then(json => {
        console.log('refreshAuthService response:', json)
        const idToken = json.id_token
        const accessToken = json.access_token
        const timeout = json.expires_in * 1000
        //const timeout = 4 * 60 * 60 * 1000  // Increased timeout for demo purpose.
        const expiry = _moment(config).valueOf() + timeout
        dispatch({
          type: 'LOGIN_REFRESH',
          idToken,
          accessToken,
          expiry,
          timeout
        })
      })
      .catch(err => {
        console.error('refreshAuthService failed with ex', err)
        dispatch(requestLogout())
        dispatch(receiveLogout())
        dispatch(push(basePath + '/'))
      })
  }
}, REFRESH_AUTH_FREQUENCY_MS)

const refreshLocalhost = _.throttle((dispatch, skipNextRefresh, isInitial) => {
  if (skipNextRefresh) {
    dispatch({
      type: 'CLEAR_SKIP_NEXT_REFRESH'
    })
  } else {
    if (isInitial) {
      dispatch({
        type: 'LOGIN_REFRESH_INITIAL'
      })
    }
    dispatch({
      type: 'LOGIN_REFRESH_LOCAL',
      expiry: _moment(config).valueOf() + config.defaultExpiryMs,
      //expiry: _moment(config).valueOf() + (4 * 60 * 60 * 1000),  // Increased timeout for demo purpose.
       timeout: config.defaultExpiryMs
      //timeout: (4 * 60 * 60 * 1000)  // Increased timeout for demo purpose.
    })
  }
}, REFRESH_AUTH_FREQUENCY_MS)

export function loginRefresh(skipNextRefresh, fetcher, isInitial) {
  return dispatch => {
    if (process.env.NODE_ENV === 'production') {
      refreshAuthService(dispatch, skipNextRefresh, fetcher, isInitial)
    } else {
      refreshLocalhost(dispatch, skipNextRefresh, isInitial)
    }
  }
}

export function loginError(message) {
  return {
    type: 'LOGIN_FAILURE',
    message
  }
}

export function requestLogout() {
  return {
    type: 'LOGOUT_REQUEST'
  }
}

export function receiveLogout() {
  return {
    type: 'LOGOUT_SUCCESS'
  }
}

export function doLogoutRedirect() {
  if (process.env.NODE_ENV === 'production' && typeof config.logoutRedirectUrl === 'string') {
    window.location.href = config.logoutRedirectUrl
  }
}

export function logoutUser(uuid: string, accessToken: string, skipRedirect: boolean) {
  return dispatch => {
    dispatch(requestLogout())
    dispatch(receiveLogout())
    dispatch(push(basePath + '/'))
    if (!skipRedirect) {
      doLogoutRedirect()
    }
  }
}

export function redirect(path) {
  return dispatch => {
    dispatch(push(path))
  }
}

export function fakeLogin() {
  return {
    type: 'LOGIN_SUCCESS',
    isFetching: false,
    isAuthenticated: true,
    userAccount: config.openId_localhostAccount,
    expiry: _moment(config).valueOf() + config.defaultExpiryMs,
    //expiry: _moment(config).valueOf() + (4 * 60 * 60 * 1000),  // Increased timeout for demo purpose.
    timeout: config.defaultExpiryMs
    //timeout: (4 * 60 * 60 * 1000)  // Increased timeout for demo purpose.
  }
}

export function updateUserRoles(userRoles, inactiveUserRoles, userAccount) {
  return {
    type: 'UPDATE_ACCESS_ROLES',
    userRoles,
    inactiveUserRoles,
    userAccount
  }
}

export function changeView(newView) {
  return {
    type: 'CHANGE_VIEW',
    currentView: newView
  }
}

export function setupUserAccess(
  activeUserRoles = [],
  inactiveUserRoles = [],
  userAccount
) {
  return dispatch => {
    if (Array.isArray(activeUserRoles) && activeUserRoles.length > 0) {
      // setup UserRoles

      const userRoleMapper = rawRole => {
        const entitlementPaths = []
        for (let i = 0; i < _.get(rawRole, 'entitlements', []).length; ++i) {
          entitlementPaths.push('entitlements[' + i + '].entitlementId')
          entitlementPaths.push('entitlements[' + i + '].entitlementName')
          entitlementPaths.push(
            'entitlements[' + i + '].entitlementDescription'
          )
        }
        return _.pick(
          rawRole,
          'userRoleId',
          'uuid',
          'dismissInactiveNotifyFlag',
          'adjustMethod',
          'role.roleId',
          'role.roleName',
          'role.multilingualDescription',
          'role.multilingualDisplayName',
          'role.status',
          'organization.orgId',
          'organization.orgNm',
          'organization.multilingualDisplayName',
          'organization.startDate',
          'organization.endDate',
          ...entitlementPaths
        )
      }

      let mappedUserRoles = activeUserRoles.map(userRoleMapper)
      let mappedInactiveUserRoles = inactiveUserRoles.map(userRoleMapper)

      dispatch(updateUserRoles(mappedUserRoles, mappedInactiveUserRoles, userAccount))

      if (
        !_.some(
          inactiveUserRoles || [],
          inactiveUserRole =>
            'Y' !== inactiveUserRole['dismissInactiveNotifyFlag'] &&
            'URURAM07111' !== _.get(inactiveUserRole, 'adjustMethod.key')
        )
      ) {
        //Only auto-select the role if they don't have an expired role notification.
        //If more than one role, then check for defaultUserRoleId
        let selectedUserRole
        if (
          mappedUserRoles.length > 1 &&
          !_.isNil(userAccount.defaultUserRoleId)
        ) {
          selectedUserRole = _.find(mappedUserRoles, {
            userRoleId: userAccount.defaultUserRoleId
          })
        } else if (mappedUserRoles.length === 1) {
          selectedUserRole = mappedUserRoles[0]
        }
        // setup selected UserRoles
        dispatch({ type: 'UPDATE_SELECTED_ROLE', selectedUserRole })
      }
    } else {
      // User has no roles.
      //TODO: We should always have a client role at least, right?  Do we want to call auth to add it if is not there?
      dispatch(updateUserRoles([], [], []))
    }
  }
}

const fakeUserRoles = {
  administrator: [
    {
      userRoleId: 1001,
      startDate: '2018-05-04T19:13:57.860Z',
      endDate: null,
      createdDate: '2018-05-04T19:13:57.860Z',
      createdUser: null,
      lastUpdateDate: null,
      lastUpdateUser: null,
      uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
      role: {
        roleId: 1,
        roleName: 'Administrator',
        roleDescription: 'This is the description for Administrator',
        displayName: 'Administrator',
        lastUpdateUser: null,
        tenantId: config.tenant.id,
        status: 'Active',
        startDate: '2018-05-03T15:19:41.320Z',
        endDate: null,
        createdDate: null,
        lastUpdateDate: null
      },
      entitlements: [
        {
          entitlementId: 1,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_USERS,
          entitlementDescription: null
        },
        {
          entitlementId: 2,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_USERS,
          entitlementDescription: null
        },
        {
          entitlementId: 3,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ROLES,
          entitlementDescription: null
        },
        {
          entitlementId: 4,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ROLES,
          entitlementDescription: null
        },
        {
          entitlementId: 5,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ENTITLEMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 6,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ENTITLEMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 7,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ORGANIZATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 8,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_ORGANIZATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 9,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_INVITATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 10,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_INVITATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 11,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_EXPORT,
          entitlementDescription: null
        },
        {
          entitlementId: 12,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESELECTCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 13,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESKIPCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 14,
          entitlementName: CATEGORIES.ADMIN_PORTAL_ACCESS_MANAGEMENT,
          entitlementDescription: null
        },
        {
          entitlementId: 15,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ATTRIBUTES,
          entitlementDescription: null
        },
        {
          entitlementId: 16,
          entitlementName: ENTITLEMENTS.ADMIN_READ_CL_WORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 17,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_COUNTYWORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 18,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_SUPERVISEWORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 19,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_WORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 20,
          entitlementName: ENTITLEMENTS.AP_WRITE_CL_WORKASSIGNMENTRULESANDTRANSFERS,
          entitlementDescription: null
        },
        // For broadcast
        {
          entitlementId: 21,
          entitlementName: ENTITLEMENTS.ADMIN_READ_ADM_BROADCASTMESSAGE,
          entitlementDescription: null
        },
        {
          entitlementId: 22,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADM_BROADCASTMESSAGE,
          entitlementDescription: null
        },
        {
          entitlementId: 23,
          entitlementName: ENTITLEMENTS.AP_SF_VIEW_CL_ORGASSIGNMENTRULES,
          entitlementDescription: null
        },
        // For Worker Messages
        {
          entitlementId: 24,
          entitlementName: ENTITLEMENTS.ADMIN_READ_CL_WORKER_MSG_CONFIGURATION,
          entitlementDescription: null
        },
        {
          entitlementId: 25,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_WORKER_MSG_CONFIGURATION,
          entitlementDescription: null
        },
        {
          entitlementId: 26,
          entitlementName: ENTITLEMENTS.ADMIN_READ_CL_WORKERMSGASSIGNMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 27,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_WORKERMSGASSIGNMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 28,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_USERACCESSREQUESTS,
          entitlementDescription: null
        },
        {
          entitlementId: 29,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_AM_USERACCESSREQUESTS,
          entitlementDescription: null
        },
        {
          entitlementId: 30,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESELECTCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 31,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESKIPCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 32,
          entitlementName: ENTITLEMENTS.ADP_SUPER_ADMIN,
          entitlementDescription: null
        }
        ///TODO: Uncomment entitlements once added to @optum-wvie/dynamic-ui-shared ENTITLEMENTS
        /*
        {
          entitlementId: 24,
          entitlementName: ENTITLEMENTS.ADMIN_READ_ADM_MESSAGE,
          entitlementDescription: null
        },
        {
          entitlementId: 25,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADM_MESSAGE,
          entitlementDescription: null
        }
        */
      ],
      "organization": {
				"orgId": 3639,
				"orgNm": "NYORG",
				"extrOrgId": null,
				"createUser": "174489e4-ec7e-4145-8027-381e1279b5d0",
				"createDate": "2019-11-12T18:09:23.190Z",
				"lastUpdateUser": "174489e4-ec7e-4145-8027-381e1279b5d0",
				"lastUpdateDate": "2019-11-12T18:09:23.190Z",
				"tenantId": 2,
				"startDate": "2019-11-12T18:09:23.190Z",
				"endDate": null,
				"overview": null,
				"webAddress": null,
				"servicesOffered": null,
				"useParentInfo": "N",
				"parentOrgId": null,
				"multilingualDisplayName": {
					"en": "NYORG"
				}
			}
    }
  ],
  worker: [
    {
      userRoleId: 1002,
      startDate: '2018-05-04T19:13:57.860Z',
      endDate: null,
      createdDate: '2018-05-04T19:13:57.860Z',
      createdUser: null,
      lastUpdateDate: null,
      lastUpdateUser: null,
      uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
      role: {
        roleId: 2,
        roleName: 'Worker',
        roleDescription: 'This is the description for Worker',
        displayName: 'Worker',
        lastUpdateUser: null,
        tenantId: config.tenant.id,
        status: 'Active',
        startDate: '2018-05-03T15:19:41.320Z',
        endDate: null,
        createdDate: null,
        lastUpdateDate: null
      },
      entitlements: [
        {
          entitlementId: 1,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_USERS,
          entitlementDescription: null
        },
        {
          entitlementId: 3,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ROLES,
          entitlementDescription: null
        },
        {
          entitlementId: 5,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ENTITLEMENTS,
          entitlementDescription: null
        },
        {
          entitlementId: 7,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ORGANIZATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 9,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_INVITATIONS,
          entitlementDescription: null
        },
        {
          entitlementId: 11,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_EXPORT,
          entitlementDescription: null
        },
        {
          entitlementId: 12,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESELECTCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 13,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_ADB_MASSCHANGESKIPCRITERIA,
          entitlementDescription: null
        },
        {
          entitlementId: 14,
          entitlementName: ENTITLEMENTS.ADMIN_READ_AM_ATTRIBUTES,
          entitlementDescription: null
        },
        {
          entitlementId: 15,
          entitlementName: ENTITLEMENTS.ADMIN_READ_CL_WORKASSIGNMENTRULES,
          entitlementDescription: null
        },
        {
          entitlementId: 16,
          entitlementName: ENTITLEMENTS.ADMIN_WRITE_CL_COUNTYWORKASSIGNMENTRULES,
          entitlementDescription: null
        }
      ]
    }
  ],
  other: [
    {
      userRoleId: 1003,
      startDate: '2018-05-04T19:13:57.860Z',
      endDate: null,
      createdDate: '2018-05-04T19:13:57.860Z',
      createdUser: null,
      lastUpdateDate: null,
      lastUpdateUser: null,
      uuid: '98887fa6-0009-4166-85c4-43c93d91bf91',
      role: {
        roleId: 3,
        roleName: 'Other',
        roleDescription: 'This is the description for Other',
        displayName: 'Other',
        lastUpdateUser: null,
        tenantId: config.tenant.id,
        status: 'Active',
        startDate: '2018-05-03T15:19:41.320Z',
        endDate: null,
        createdDate: null,
        lastUpdateDate: null
      },
      entitlements: []
    }
  ]
}
const fakeInactiveRoles = {
  administrator: [
    {
      userRoleId: 103520,
      startDate: '2019-08-12T14:26:24.190Z',
      endDate: '2019-08-12T21:05:59.740Z',
      createdDate: '2019-08-12T14:26:24.190Z',
      createdUser: null,
      lastUpdateDate: '2019-08-12T21:05:59.740Z',
      lastUpdateUser: null,
      uuid: '174489e4-ec7e-4145-8027-381e1279b5d0',
      role: {
        roleId: 8541,
        roleName: 'UserStoryRole',
        roleDescription: null,
        createdUser: 'IEAutomation2',
        lastUpdateUser: null,
        tenantId: 2,
        status: 'InActive',
        numUsers: null,
        displayName: 'User story test',
        inactiveAfterNumber: 1,
        inactiveAfterUnit: {
          category: 'ROLE_INACTIVITY',
          subCategory: 'ROLE_INACTIVITY_UNIT',
          languageCode: 'en',
          key: 'RIRUDD12904',
          value: 'Days',
          rulesEngineCode: null,
          sortOrder: 9999
        },
        startDate: '2019-08-12T05:00:00.000Z',
        endDate: '2019-08-11T05:00:00.000Z',
        createdDate: '2019-08-12T14:12:50.120Z',
        lastUpdateDate: '2019-08-12T17:13:57.707Z',
        multilingualDescription: {
          en: null
        },
        multilingualDisplayName: {
          en: 'User story test'
        },
        isOrgRequired: true
      },
      entitlements: [
        {
          entitlementId: 2752,
          entitlementName: 'McutFAqc',
          entitlementDescription: 'McutFAqc',
          createdUser: '7b14762a-d7cc-4fcb-8313-3e094e0a1814',
          lastUpdateUser: 'SYSTEM',
          tenantId: 2,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'PRY',
            languageCode: 'en',
            key: 'ENCAPR07263',
            value: 'Primary',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          createdDate: '2019-07-17T11:32:22.030Z',
          lastUpdateDate: '2019-07-29T18:52:10.880Z'
        }
      ],
      organization: {
        orgId: 1002,
        orgNm: 'MN Test Org',
        extrOrgId: null,
        createUser: 'SYSTEM',
        createDate: '2018-05-28T10:38:43.000Z',
        lastUpdateUser: 'SYSTEM',
        lastUpdateDate: '2019-07-31T17:22:14.923Z',
        tenantId: 2,
        startDate: '2018-09-26T14:38:23.000Z',
        endDate: null,
        overview: null,
        webAddress: null,
        servicesOffered: null,
        useParentInfo: null,
        parentOrgId: null,
        multilingualDisplayName: {
          en: 'MN Test Org',
          es: 'TRANSLATE: MN Test Org'
        }
      },
      userId: null,
      dismissInactiveNotifyFlag: 'N',
      adjustMethod: {
        category: 'USER_ROLE_ADJUSTMENT',
        subCategory: 'USER_ROLE_ADJUSTMENT_METHOD',
        languageCode: 'en',
        key: 'URURAM07112',
        value: 'System',
        rulesEngineCode: null,
        sortOrder: 9999
      }
    },
    {
      userRoleId: 103521,
      startDate: '2019-08-12T14:26:24.190Z',
      endDate: '2019-08-12T21:05:59.740Z',
      createdDate: '2019-08-12T14:26:24.190Z',
      createdUser: null,
      lastUpdateDate: '2019-08-12T21:05:59.740Z',
      lastUpdateUser: null,
      uuid: '174489e4-ec7e-4145-8027-381e1279b5d0',
      role: {
        roleId: 8541,
        roleName: 'UserStoryRole',
        roleDescription: null,
        createdUser: 'IEAutomation2',
        lastUpdateUser: null,
        tenantId: 2,
        status: 'InActive',
        numUsers: null,
        displayName: 'User story test',
        inactiveAfterNumber: 1,
        inactiveAfterUnit: {
          category: 'ROLE_INACTIVITY',
          subCategory: 'ROLE_INACTIVITY_UNIT',
          languageCode: 'en',
          key: 'RIRUDD12904',
          value: 'Days',
          rulesEngineCode: null,
          sortOrder: 9999
        },
        startDate: '2019-08-12T05:00:00.000Z',
        endDate: '2019-08-11T05:00:00.000Z',
        createdDate: '2019-08-12T14:12:50.120Z',
        lastUpdateDate: '2019-08-12T17:13:57.707Z',
        multilingualDescription: {
          en: null
        },
        multilingualDisplayName: {
          en: 'User story test'
        },
        isOrgRequired: true
      },
      entitlements: [
        {
          entitlementId: 2752,
          entitlementName: 'McutFAqc',
          entitlementDescription: 'McutFAqc',
          createdUser: '7b14762a-d7cc-4fcb-8313-3e094e0a1814',
          lastUpdateUser: 'SYSTEM',
          tenantId: 2,
          category: {
            category: 'ENTITLEMENT_CATEGORY',
            subCategory: 'PRY',
            languageCode: 'en',
            key: 'ENCAPR07263',
            value: 'Primary',
            rulesEngineCode: null,
            sortOrder: 9999
          },
          createdDate: '2019-07-17T11:32:22.030Z',
          lastUpdateDate: '2019-07-29T18:52:10.880Z'
        }
      ],
      organization: {
        orgId: 1002,
        orgNm: 'MN Test Org',
        extrOrgId: null,
        createUser: 'SYSTEM',
        createDate: '2018-05-28T10:38:43.000Z',
        lastUpdateUser: 'SYSTEM',
        lastUpdateDate: '2019-07-31T17:22:14.923Z',
        tenantId: 2,
        startDate: '2018-09-26T14:38:23.000Z',
        endDate: null,
        overview: null,
        webAddress: null,
        servicesOffered: null,
        useParentInfo: null,
        parentOrgId: null,
        multilingualDisplayName: {
          en: 'MN Test Org',
          es: 'TRANSLATE: MN Test Org'
        }
      },
      userId: null,
      dismissInactiveNotifyFlag: 'N',
      adjustMethod: {
        category: 'USER_ROLE_ADJUSTMENT',
        subCategory: 'USER_ROLE_ADJUSTMENT_METHOD',
        languageCode: 'en',
        key: 'URURAM07112',
        value: 'System',
        rulesEngineCode: null,
        sortOrder: 9999
      }
    }
  ]
}

export function getStaticTranslations(locales): any {
  return dispatch => {
    const endpoints = [
      ...locales.map(locale => {
        return (
          config['endpoint']['sharedStaticTranslation'] +
          '.' +
          locale
        ).replace('{version}', '1.0')
      }),
      ...locales.map(locale => {
        return (
          config['endpoint']['adminStaticTranslation'] +
          '.' +
          locale
        ).replace('{version}', '1.0')
      })
    ]
    const apiRequests = endpoints.map(endpoint => {
      return fetchJson(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          tenantCode: config.tenant.code,
          portalName: config.portalName
        }
      })
        .then(json => {
          return json
        })
        .catch(error => {
          console.error(
            'getTranslations failed at endpoint ' + endpoint + ' due to error:',
            error
          )
        })
    })
    Promise.all(apiRequests).then(results => {
      const translationsObject = {}
      for (let i = 0; i < locales.length; ++i) {
        translationsObject[locales[i]] = _.merge(
          {},
          results[i],
          results[locales.length + i]
        )
      }
      dispatch(loadTranslations(translationsObject))
    })
  }
}
export function setUserRolesDismissed(dismissedUserRoleIds: number[]) {
  return {
    type: 'DISMISS_USER_ROLE_INACTIVE_NOTIFY',
    dismissedUserRoleIds
  }
}
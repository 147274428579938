import * as React from 'react'
import * as ReactDOM from 'react-dom'
import configureStore from '~/src/redux/configureStore'
import configureTenant from './configureTenant'
import { config } from '~/config'
import './styles/scss/admin-portal.scss'
import Root from './components/Root'
import { setLocale, syncTranslationWithStore } from 'react-redux-i18n'
import { getStaticTranslations } from '~/src/redux/actions'
import 'whatwg-fetch'


const tenantConfig = configureTenant(config.environment, null)
const store = configureStore(config.environment, tenantConfig.code)
syncTranslationWithStore(store)
store.dispatch(getStaticTranslations(['en']) as any)
store.dispatch(setLocale('en'))

if (process.env.NODE_ENV !== 'production') {
    const axe = require('@axe-core/react')
    axe(React, ReactDOM, 1000)
    ReactDOM.render(<Root store={store} />, document.getElementById('root'))
} else {
    ReactDOM.render(<Root store={store} />, document.getElementById('root'))
}

import * as React from 'react'
import { config } from '~/config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-shared/src/utils'
import { ErrorBoundary } from '../../Errors'
import { BroadcastMessageAlertContainerState } from './BroadcastMessageAlert'
import { BroadcastMessageAlertContainerProps } from './BroadcastMessageAlert'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const BroadcastMessageAlertContainer = MultitenantLoadable(
  () => import('./BroadcastMessageAlertContainer'),
  () =>
    import('../../' + tenantDirectoryName + '/BroadcastMessageAlert/BroadcastMessageAlertContainer')
)
const BroadcastMessageAlertPresentation = MultitenantLoadable(
  () => import('./BroadcastMessageAlertPresentation'),
  () =>
    import(
      '../../' + tenantDirectoryName + '/BroadcastMessageAlert/BroadcastMessageAlertPresentation'
    )
)

export default function BroadcastMessageAlert(props: BroadcastMessageAlertContainerProps) {
  return (
    <ErrorBoundary name="BroadcastMessageAlert">
      <BroadcastMessageAlertContainer
        {...props}
        presentation={presentationProps => (
          <BroadcastMessageAlertPresentation {...presentationProps} />
        )}
      />
    </ErrorBoundary>
  )
}

import { config } from '~/config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-shared/src/utils'
import { AdminPortalException, CATEGORIES, CODES, shouldThrow } from './Errors'

const tenantDirectoryName = config.tenant.code.toLowerCase()

const ErrorBoundary = MultitenantLoadable(
  () => import(/* webpackChunkName: "Errors" */ './Errors'),
  () => import(/* webpackChunkName: "[request]" */ '../../' + tenantDirectoryName + '/Errors'),
  'ErrorBoundary'
)

const BaseErrorBoundary = MultitenantLoadable(
  () => import('./Errors'),
  () => Promise.resolve().then(() => null),
  'ErrorBoundary'
)

export {
  ErrorBoundary,
  AdminPortalException,
  CATEGORIES,
  CODES,
  shouldThrow,
  BaseErrorBoundary
}

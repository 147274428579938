import * as React from 'react'
import { Provider } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router'
import { ConnectedRouter } from 'react-router-redux'
import { history } from '../redux/configureStore'
import { config } from '~/config'
import {
  _moment
} from '@optum-wvie/dynamic-ui-framework/src/utils'
import {
  isLoggedIn
} from '@optum-wvie/dynamic-ui-shared/src/utils'
import * as queryString from 'query-string'
import { connect } from 'react-redux'
import LandingPage from './LandingPage'
import FakeLogin from './FakeLogin'
import Main from '../../src/components/base/Main'
import IdleTimer from 'react-idle-timer'
import { Modal } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import ScreenPreLoader from '@optum-wvie/dynamic-ui-shared/components/ScreenPreLoader'
import {
  get,
  round,
  some,
  includes,
  forEach,
  uniq
} from 'lodash'
const _ = {
  get,
  round,
  some,
  includes,
  forEach,
  uniq
}
import * as actions from '~/src/redux/actions'
import AccessManagement from './base/AccessManagement'
import Dashboard from './Dashboard'
//import MassChange from './base/Batch/MassChange'
import { ErrorBoundary } from './Errors'
import { withAdminFetcher } from '~/src/utils'
import ReactMatomo from 'react-piwik'
import { CATEGORIES, ENTITLEMENTS } from '@optum-wvie/dynamic-ui-shared/src/entitlements'
import { v4 } from 'uuid'

const basePath = config.basePath

const isMatomoEnabled = (config.matomoUrl && config.matomoSiteId && process.env.NODE_ENV === 'production')
const matomo = isMatomoEnabled ? 
  new ReactMatomo({
    url: config.matomoUrl,
    siteId: config.matomoSiteId,
    //trackErrors: true,
    jsFilename: 'matomo.js',
    phpFilename: 'piwik.php'
  })
  : null;

if (isMatomoEnabled) {
  ReactMatomo.push(['trackPageView'])
}

const Root = ({ store }) => {
  return (
    <Provider store={store}>
      <ErrorBoundary name="Root">
        <ConnectedApp />
      </ErrorBoundary>
    </Provider>
  )
}

//Events that will refresh the user session.
const ACTIVITY_EVENTS: any = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove'
]

class App extends React.Component<any, any> {
  private idleTimer
  constructor(props) {
    super(props)

    const initialUrl = window.location.href
    let code
    const { url, query } = queryString.parseUrl(window.location.href)
    if (query && query.code) {
      code = query.code
    }

    this.idleTimer = null

    this.state = {
      initialUrl,
      code,
      tabFocus: false,
      showCountdownModal: false,
      countdownIntervalId: null,
      remainingSeconds: null
    }
  }

  componentDidMount() {
    const { auth, loginRefresh, logoutUser, loginUser, redirect } = this.props
    const { skipNextRefresh } = auth
    const { code, initialUrl } = this.state

    if (auth.isAuthenticated || auth.userAccount || auth.expiry) {
      if (code) {
        //Logout so we can login again.
        logoutUser(_.get(auth, 'userAccount.uuid'), _.get(auth, 'accessToken'), true)
      } else if (isLoggedIn(auth, config)) {
        if (isMatomoEnabled) {
          ReactMatomo.push(['setUserId', this.props.auth.userAccount.uuid])
          ReactMatomo.push(['setCustomDimension', config.matomoCustomDimensionGovIdUsername, this.props.auth.userAccount.userId]);

        }
        loginRefresh(skipNextRefresh, this.props.adminFetch, true)
      } else {
        logoutUser(_.get(auth, 'userAccount.uuid'), _.get(auth, 'accessToken'), false)
      }
    }

    if (code) {
      const { url, query } = queryString.parseUrl(initialUrl)
      loginUser(code, query.state, url)
    }


  }

  componentDidUpdate(prevProps, prevState) {
    const { code } = this.state
    if (code && isLoggedIn(this.props.auth, config)) {
      if (isMatomoEnabled) {
        ReactMatomo.push(['setUserId', this.props.auth.userAccount.uuid])
        ReactMatomo.push(['setCustomDimension', config.matomoCustomDimensionGovIdUsername, this.props.auth.userAccount.userId]);
      }
      this.setState({ code: null })
    }
  }

  _openIdRedirect = () => {
    const loginUrl = config.openId_loginUrl.replace('{state}', v4())
    window.location.href = loginUrl
    return null
  }

  onAction = e => {
    const { auth, loginRefresh } = this.props
    const { showCountdownModal } = this.state
    if (isLoggedIn(auth, config) && !showCountdownModal) {
      const { skipNextRefresh } = auth
      loginRefresh(skipNextRefresh, this.props.adminFetch, false)
    }
  }

  onActive = e => {}

  onIdle = e => {
    const timeLeft =
      _.get(this.props, 'auth.expiry', 0) - _moment(config).valueOf()
    const { auth, logoutUser, redirect } = this.props
    if (timeLeft > 0) {
      const countdownIntervalId = setInterval(this.onCountdownTick, 1000)
      this.setState({
        remainingSeconds: _.round(timeLeft / 1000),
        showCountdownModal: true,
        countdownIntervalId
      })
    } else {
      logoutUser(_.get(auth, 'userAccount.uuid'), _.get(auth, 'accessToken'), false)
      this.setState({
        remainingSeconds: null,
        showCountdownModal: false,
        countdownIntervalId: null
      })
    }
  }

  onCountdownTick = () => {
    const timeLeft =
      _.get(this.props, 'auth.expiry', 0) - _moment(config).valueOf()
    if (timeLeft <= 0) {
      this.onCountdownLogout()
    } else {
      this.setState({ remainingSeconds: _.round(timeLeft / 1000) })
    }
  }

  onCountdownContinue = () => {
    clearInterval(this.state.countdownIntervalId)
    const { skipNextRefresh } = this.props.auth
    this.props.loginRefresh(skipNextRefresh, this.props.adminFetch, false)
    this.setState({
      remainingSeconds: null,
      showCountdownModal: false,
      countdownIntervalId: null
    })
  }

  onCountdownLogout = () => {
    const { auth, logoutUser, redirect } = this.props
    clearInterval(this.state.countdownIntervalId)
    logoutUser(_.get(auth, 'userAccount.uuid'), _.get(auth, 'accessToken'), false)
    this.setState({
      remainingSeconds: null,
      showCountdownModal: false,
      countdownIntervalId: null
    })
  }

  redirect = (url) => {
    window.location.href = url
    return null
  }

	hasAdminEntitlement = entitlements => {
		const adminEntitlements = [
			ENTITLEMENTS.ADMIN_READ_AM_USERACCESSREQUESTS,
			ENTITLEMENTS.ADMIN_WRITE_AM_USERACCESSREQUESTS,
			...CATEGORIES.ADMIN_PORTAL_ACCESS_MANAGEMENT,
			...CATEGORIES.ADMIN_PORTAL_MASS_CHANGE,
			ENTITLEMENTS.ADMIN_READ_CL_WORKASSIGNMENTRULES,
			...CATEGORIES.ADMIN_PORTAL_BROADCAST_MESSAGE
		]
		return _.some(adminEntitlements, entitlement => _.includes(entitlements, entitlement))
	}

  render() {
    const { auth, userAccess, routes, isFetching, logoutUser, activeEntitlements } = this.props
    const { tabFocus, showCountdownModal, remainingSeconds } = this.state
    const isLoggedInTemp = isLoggedIn(auth, config)

    const idleTimeout =
      (auth.timeout || config.defaultExpiryMs) - config.countdownTimerMs

    if (isLoggedInTemp) {
      return (
        <div className="provider-area">
          {!showCountdownModal && (
            <IdleTimer
              ref={ref => {
                this.idleTimer = ref
              }}
              events={ACTIVITY_EVENTS}
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={500}
              timeout={idleTimeout}
              stopOnIdle={true}
            />
          )}
          <ConnectedRouter history={matomo ? matomo.connectToHistory(history) : history}>
            {isFetching ? (
              <ScreenPreLoader style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}/>
            ) : (
              <Switch>
                <Route
                  path={basePath + '/login'}
                  render={() => {
                    return <Redirect to={basePath + '/dashboard'} />
                  }}
                />
                  <Route path={basePath + '/childsupport'}
                    render={() => {
                      return this.redirect(config.childSupportUrl)
                    }} />
                  <Route path={basePath + '/childwelfare'}
                    render={() => {
                      return this.redirect(config.childWelfareUrl)
                    }} />
                <Route path={basePath + '/fake-login'} component={FakeLogin} />
                <Route
                  path={basePath + '/doLogin'}
                  render={() => {
                    return (
                      <Redirect
                        to={{ pathname: basePath + '/dashboard' }}
                        from={basePath + '/doLogin'}
                      />
                    )
                  }}
                />
                <Route
                 path={basePath + '/logout'}
                 render={() => {
                  logoutUser(_.get(auth, 'userAccount.uuid'), _.get(auth, 'accessToken'), false)
                   return (
                     <Redirect to={{ pathname: basePath + '/' }} />
                   )
                 }}
                />
                <Route
                  exact
                  path={basePath + '/'}
                  render={() => {
                    return (
                      <Redirect
                        to={{ pathname: basePath + '/dashboard' }}
                        from={basePath + '/'}
                      />
                    )
                  }}
									/>
									<Route
										path={basePath + '/no-access'}
										component={Main}
									/>
									{
										!this.hasAdminEntitlement(activeEntitlements) ? (
											<Route
												path={basePath + '/*'}
												render={() => {
													return <Redirect to={basePath + '/no-access'} />
												}}
											/>
										) : (
												<Route
													path={basePath + '/*'}
													component={Main}
													auth={this.props.auth}
												/>
											)
									}
              </Switch>
            )}
          </ConnectedRouter>
          {showCountdownModal && (
            <Modal
              show={true}
              onHide={this.onCountdownContinue}
              aria-labelledby="contained-modal-title"
              bsSize="sm"
              backdrop="static"
            >
              <Modal.Header>
                <Modal.Title>
                  <i className="fa fa-exclamation-triangle" />
                  {' ' + I18n.t('Root.countdown.title')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {I18n.t('Root.countdown.message', {
                  remainingSeconds: remainingSeconds.toString()
                })}
              </Modal.Body>
              <Modal.Footer>
                <button
                  id="Root_countdownContinueBtn"
                  type="button"
                  className="btn btn-primary float-left"
                  onClick={this.onCountdownContinue}
                >
                  {I18n.t('Root.countdown.continueBtn')}
                </button>
                <button
                  id="Root_countdownLogoutBtn"
                  type="button"
                  className="btn btn-warning float-right"
                  onClick={this.onCountdownLogout}
                >
                  {I18n.t('Root.countdown.logoutBtn')}
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      )
    } else if (this.state.code) {
      return <div>Loading...</div>
    } else if (process.env.NODE_ENV !== 'production') {
      return (
        <ConnectedRouter history={matomo ? matomo.connectToHistory(history) : history}>
          <Switch>
            <Route
              exact
              path={basePath + '/'}
              render={() => {
                return <LandingPage />
              }} />
            <Route path={basePath + '/login'}
              render={() => {
                return <FakeLogin />
              }} />
            <PrivateRoute
              auth={auth}
              path={basePath + '/dashboard'}
              component={Dashboard}
              componentProps={null}
            />
            <PrivateRoute
              auth={auth}
              path={basePath + '/access-management'}
              component={AccessManagement}
              componentProps={null}
            />
            {/* <PrivateRoute
              auth={auth}
              path={basePath + '/mass-change'}
              component={MassChange}
              componentProps={null}
            /> */}
          </Switch>
        </ConnectedRouter>
      )
    } else if (typeof config.logoutRedirectUrl === 'string') {
      actions.doLogoutRedirect()
      return null
    } else {
      return (
        <ConnectedRouter history={matomo ? matomo.connectToHistory(history) : history}>
          <Switch>
            <Route
              exact
              path={basePath + '/'}
              render={() => {
                return <LandingPage />
              }} />
            <Route path={basePath + '/login'}
              render={() => {
                return this._openIdRedirect()
              }} />
          </Switch>
        </ConnectedRouter>
      )
    }
  }
}

const ConnectedApp = withAdminFetcher(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
)

function mapStateToProps(state) {
  let activeEntitlements = []
  if (state.userAccess && state.userAccess.userRoles) {
    _.forEach(state.userAccess.userRoles, userRole => {
      _.forEach(userRole.entitlements, entitlement => {
        activeEntitlements.push(entitlement.entitlementName)
      })
    })
    activeEntitlements = _.uniq(activeEntitlements)
  }
  return {
    auth: state.auth,
    userAccess: state.userAccess,
    isFetching: state.auth.isFetching || state.userAccess.isFetching,
    locale: _.get(state.i18n, 'locale'),
    activeEntitlements
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loginUser: (code, state, redirectUri) => {
      dispatch(actions.loginUser(code, state, redirectUri))
    },
    logoutUser: (uuid, accessToken, skipRedirect) => {
      dispatch(actions.logoutUser(uuid, accessToken, skipRedirect))
    },
    redirect: path => {
      dispatch(actions.redirect(path))
    },
    loginRefresh: (skipNextRefresh, fetcher, isInitial) => {
      dispatch(actions.loginRefresh(skipNextRefresh, fetcher, isInitial))
    }
  }
}

function PrivateRoute({ component: Component, auth, componentProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn(auth, config) ? (
          <Component {...componentProps} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: basePath + '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

export default Root

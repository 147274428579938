import { includes } from 'lodash'
import * as queryString from 'query-string'

const assetBase = process.env.ASSET_BASE

const _ = { includes }

export function isImplemented() {
  ///TODO: In Minnesota's implementation fork, return true here.
  return false
}

export function getConfig(baseConfig: any) {
  let config: any = {
    tenant: {
      id: 2,
      code: 'mn',
      state: 'MN',
      name: 'Minnesota',
      fetcher: {
        retries: 3,
        timeoutMs: 5000
      },
      timeZone: 'America/Chicago',
      assignmentStrategy: 'CASE',
      isUpdateLockedUserInfoEnabled: false
    }
  }

  //Retrieve environment-level config.
  switch (baseConfig.environment) {
    case 'LOCAL':
      config = {
        ...config,
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: null,
        matomoCustomDimensionCorrelationId: null,
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null
        //"gatewayBaseUrl": "https://mn-ie-api-gw-tst.optum.com" //TODO
      }
      break
    case 'DEV':
    case 'OS-DEV':  
      config = {
        ...config,
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 3,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null
        //"gatewayBaseUrl": "https://mn-ie-api-gw-dev.optum.com" //TODO
      }
      break
    case 'TEST':
    case 'OS-TST':
      config = {
        ...config,
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 6,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null
        //"gatewayBaseUrl": "https://mn-ie-api-gw-tst.optum.com" //TODO
      }
      break
    case 'STAGING':
      config = {
        ...config,
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null
        //"gatewayBaseUrl": "https://mn-ie-api-gw-stg.optum.com" //TODO
      }
      break
    case 'DEMO':
      config = {
        ...config,
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null
        //"gatewayBaseUrl": "https://mn-ie-api-gw-demo.optum.com" //TODO
      }
      break
    case 'OS-PRF': 
      config = {
        ...config,
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: 30,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        isAuditingServiceEnabled: true,
        logoutRedirectUrl: null
        //"gatewayBaseUrl": "https://mn-ie-api-gw-demo.optum.com" //TODO
      }
      break
    default:
      config = {
        ...config,
        matomoUrl: 'https://hcck8s-ie-apigw-tst.optum.com',
        matomoSiteId: null,
        matomoCustomDimensionGovIdUsername: 1,
        matomoCustomDimensionCorrelationId: 2,
        logoutRedirectUrl: null
        //"gatewayBaseUrl": "https://mn-ie-api-gw-dev.optum.com" //TODO
      }
  }

  return config
}

export function determineEnvironment() {
  const urlHost = new URL(window.location.href).host;
  switch (urlHost) {
    case "localhost":
      return "LOCAL";
    case "ie-mn-admin-dev-nofe.optum.com":
      return "DEV";
    case "ie-mn-admin-tst-nofe.optum.com":
      return "TEST";
    case "ie-mn-admin-prf-nofe.optum.com":
      return "PERF";
    default:
      return null;
  }
}

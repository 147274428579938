import { createStore, applyMiddleware } from 'redux'
import { set } from 'lodash'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware, push } from 'react-router-redux'
import reducer from './reducers'
import SessionStorage from '@optum-wvie/dynamic-ui-framework/src/sessionStorage'
// import * as stringify from 'fast-json-stable-stringify';
import { createBrowserHistory } from 'history'

//const stringify: Function = require('fast-json-stable-stringify')

const stringify = JSON.stringify

export const history = createBrowserHistory()

const middlewares = [thunkMiddleware, routerMiddleware(history)]

const configureStore = (environment, tenantCode) => {
  const stateStorageKey = `IE-${environment}-ADMIN-${tenantCode}-STATE`
  let persistedState = JSON.parse(SessionStorage.getItem(stateStorageKey) || '{}')
  set(persistedState, 'errors', {errorDesc: ''})

  const store = createStore(
    reducer,
    persistedState,
    applyMiddleware(...middlewares)
  )

  store.subscribe(
    () => {
      SessionStorage.setItem(stateStorageKey, stringify(store.getState()))
    }
  )

  return store
}

export default configureStore

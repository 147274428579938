import * as React from 'react'
import { connect } from 'react-redux'
import * as actions from '~/src/redux/actions'
import { get } from 'lodash'

const _ = { get }

class FakeLogin extends React.Component<any, any> {
  constructor(props) {
    super(props)
  }

  _login = role => {
    this.props.fakeLoginUser(role)
  }

  _logout = () => {
    const { auth, logoutUser } = this.props
    logoutUser(_.get(auth, 'userAccount.uuid'), _.get(auth, 'accessToken'), true)
  }

  render() {
    return (
      <div className="container">
        <h1>Login As...</h1>
        <button
          className="btn btn-primary"
          onClick={() => this._login('administrator')}
        >
          Admin (Full Access)
        </button>
        <button
          className="btn btn-primary"
          onClick={() => this._login('worker')}
        >
          Worker (Read Only Access)
        </button>
        <button
          className="btn btn-primary"
          onClick={() => this._login('other')}
        >
          Other (No Access)
        </button>
        <h2>Or...</h2>
        <button className="btn btn-primary" onClick={() => this._logout()}>
          Logout (Clear Login)
        </button>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fakeLoginUser: role => {
      dispatch(actions.fakeLoginUser(role))
    },
    logoutUser: (uuid, accessToken, skipRedirect) => {
      dispatch(actions.logoutUser(uuid, accessToken, skipRedirect))
    },
    redirect: path => {
      dispatch(actions.redirect(path))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FakeLogin)

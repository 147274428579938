import * as React from 'react'
import { uniqBy, some } from 'lodash'
import { config } from '../../../../config'
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-shared/src/utils'
import { ErrorBoundary, BaseErrorBoundary } from '../../Errors'

const _ = { uniqBy, some }

const tenantDirectoryName = config.tenant.code.toLowerCase()

const withMainPanelTabs = (
  WrappedComponent,
  basePromiseThunk,
  tenantPromiseThunk
) => {
  return class extends React.Component<any, any> {
    constructor(props) {
      super(props)
      this.state = {
        mainPanelTabs: undefined
      }
    }

    componentDidMount() {
      Promise.all([tenantPromiseThunk(), basePromiseThunk()])
        .then(result => {
          const tenantTabs = result[0].MainPanelTabs
          const baseTabs = (result[1].MainPanelTabs || []).filter(
            baseTab => !_.some(tenantTabs, tenantTab => tenantTab.id === baseTab.id)
          )
          this.setState({
            mainPanelTabs:
              [
                ...baseTabs,
                ...tenantTabs,
              ]
          })
        })
        .catch(() => {
          basePromiseThunk().then(baseTabs => {
            this.setState({
              mainPanelTabs: baseTabs.MainPanelTabs
            })
          })
        })
    }

    render() {
      return (
        <WrappedComponent
          mainPanelTabs={this.state.mainPanelTabs}
          {...this.props}
        />
      )
    }
  }
}

const MainComponent = withMainPanelTabs(
  MultitenantLoadable(
    () => import('./Main'),
    () =>
      import('../../' + tenantDirectoryName + '/Main/Main')
  ),
  () => import('./tabs'),
  () => import('../../' + tenantDirectoryName + '/Main/tabs')
)

export default function Main(props) {
  return (
    <ErrorBoundary name="Main">
      <MainComponent
        {...props}
      />
    </ErrorBoundary>
  )
}

//Export component with no tenancy, for use in base unit tests.

const BaseMainComponent = withMainPanelTabs(
  MultitenantLoadable(
    () => import('./Main'),
    () => Promise.resolve().then(() => null)
  ),
  () => import('./tabs'),
  () => Promise.resolve().then(() => null)
)


export function BaseMain(props) {
  return (
    <BaseErrorBoundary name="Main">
      <BaseMainComponent
        {...props}
      />
    </BaseErrorBoundary>
  )
}
